import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { FaShopify } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { ShopifyService } from "../../../../services/shopify.service";
import * as yup from "yup";
import { colors } from "../../../../constants/colors";
import { apiRoutes } from "../../../../constants/api-routes";
import { IntegrationsService, SaveShopifyCredentialsDto } from "../../../../services/integrations.service";

const schema = yup.object({
  shopifyShopName: yup.string().required("'Shop Name' é obrigatório"),
  shopifyAdminAccessToken: yup
    .string()
    .required("'Admin Access Token' é obrigatório"),
  shopifyApiKey: yup.string().required("'API Key' é obrigatória"),
  shopifyApiSecretKey: yup
    .string()
    .required("'API Secret' é obrigatória"),
});

const ShopifyIntegrationPage = () => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data: shopifyConfig }  = useQuery(apiRoutes.getShopifyConfig(), async () => {
    const { data } = await IntegrationsService.getShopifyConfig();
    return data;
  }, {
    onSuccess: (data) => {
      setValue("shopifyShopName", data.shopifyShopName || "");
    },
  });

  const saveShopifyCredentials = useMutation(
    (data: SaveShopifyCredentialsDto) => IntegrationsService.saveShopifyCredentials(data),
    {
      onSuccess: () => {
        toast({
          title: "Configurações salvas com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const syncShopifyOrders = useMutation(
    () => ShopifyService.syncShopifyOrders(),
    {
      onSuccess: () => {
        toast({
          title: "Sincronização com Shopify realizada com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  async function onSubmit(data: any) {
    await saveShopifyCredentials.mutateAsync(data);
  }

  return (
    <Box padding="20px">
      <Heading size="md">Configurar integração</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} mt={4}>
          <FormControl>
            <FormLabel>Shop Name</FormLabel>
            <Input
              placeholder="Ex: minha-loja-shopify"
              {...register("shopifyShopName")}
              isInvalid={errors.shopifyShopName?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.shopifyShopName?.message}
            </Text>
          </FormControl>

          <FormControl>
            <FormLabel>Admin Access Token</FormLabel>
            <Input
placeholder="************"
              sx={{
                '&::placeholder': {
                  color: 'black'
                }
              }}
              {...register("shopifyAdminAccessToken")}
              isInvalid={errors.shopifyAdminAccessToken?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.shopifyAdminAccessToken?.message}
            </Text>
          </FormControl>

          <FormControl>
            <FormLabel>API Key</FormLabel>
            <Input
              placeholder="************"
              sx={{
                '&::placeholder': {
                  color: 'black'
                }
              }}
              {...register("shopifyApiKey")}
              isInvalid={errors.shopifyApiKey?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.shopifyApiKey?.message}
            </Text>
          </FormControl>

          <FormControl>
            <FormLabel>API Secret</FormLabel>
            <Input
              placeholder="************"
              sx={{
                '&::placeholder': {
                  color: 'black'
                }
              }}
              {...register("shopifyApiSecretKey")}
              isInvalid={errors.shopifyApiSecretKey?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.shopifyApiSecretKey?.message}
            </Text>
          </FormControl>

          <Flex justify="flex-end">
            <Button
              type="submit"
              color={colors.white}
              bgColor={colors.primary}
              width="fit-content"
              isLoading={saveShopifyCredentials.isLoading}
            >
              Salvar
            </Button>
          </Flex>
        </Stack>
      </form>
      <Divider my={4} />
      <Button
        onClick={() => syncShopifyOrders.mutateAsync()}
        isLoading={syncShopifyOrders.isLoading}
        leftIcon={<FaShopify />}
        width="fit-content"
        isDisabled={!shopifyConfig?.shopifyShopName}
      >
        Sincronizar pedidos da Shopify
      </Button>
    </Box>
  );
};

export default ShopifyIntegrationPage;
