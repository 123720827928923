import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox,
  Box,
  Flex,
  Card,
  CardBody,
  CardHeader,
  Center,
} from '@chakra-ui/react';
import { capitalize, noop } from 'lodash';
import {
  ChatAgentMetricsReport,
  ReportsService,
} from '../../../../../services/reports.service';
import { useQuery } from 'react-query';
import { apiRoutes } from '../../../../../constants/api-routes';
import { start } from 'repl';
import { DateUtils } from '../../../../../utils/date.utils';
import { useState } from 'react';
import { AxiosResponse } from 'axios';

interface CardChatAgentMetricsTableProps {
  startDate: Date;
  endDate: Date;
}

const CardChatAgentMetricsTable = ({
  startDate,
  endDate,
}: CardChatAgentMetricsTableProps) => {
  const [dataWithSummary, setDataWithSummary] = useState<
    ChatAgentMetricsReport[]
  >([]);

  const handleGetChatAgentMetricReportQuerySuccess = (
    response: AxiosResponse<ChatAgentMetricsReport[]>,
  ) => {
    const summary: ChatAgentMetricsReport = response.data.reduce(
      (acc, curr) => {
        acc.allocatedTickets += curr.allocatedTickets;
        acc.answeredTickets += curr.answeredTickets;
        acc.messagesSent += curr.messagesSent;
        acc.messagesReceived += curr.messagesReceived;
        acc.finishedTickets += curr.finishedTickets;
        acc.averageTimeToFirstResponse += curr.averageTimeToFirstResponse;
        acc.averageTimeToFinish += curr.averageTimeToFinish;
        return acc;
      },
      {
        agentId: '0',
        agentName: undefined,
        allocatedTickets: 0,
        answeredTickets: 0,
        messagesSent: 0,
        messagesReceived: 0,
        finishedTickets: 0,
        averageTimeToFirstResponse: 0,
        averageTimeToFinish: 0,
      },
    );
    setDataWithSummary([summary, ...response.data]);
  };

  useQuery(
    apiRoutes.getChatAgentMetricsReport(startDate, endDate),
    () => ReportsService.getChatAgentMetricsReport(startDate, endDate),
    {
      onSuccess: handleGetChatAgentMetricReportQuerySuccess,
      enabled:
        DateUtils.isValidDate(startDate) && DateUtils.isValidDate(endDate),
    },
  );

  return (
    <Card>
      <CardHeader>
        <Center>
          <b>Métricas por atendente</b>
        </Center>
      </CardHeader>
      <CardBody>
        <TableContainer className="business-hours-table">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Atendente</Th>
                <Th>Tickets alocados</Th>
                <Th>Tickets com resposta</Th>
                <Th>Mensagens enviadas</Th>
                <Th>Mensagens recebidas</Th>
                <Th>Tickets finalizados</Th>
                <Th>Tempo mediano para primeira resposta</Th>
                <Th>Tempo mediano para finalizar atendimento</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dataWithSummary.map((row) => (
                <Tr
                  key={row.agentId}
                  borderBottomWidth={row.agentId === '0' ? '2px' : ''}
                >
                  <td>{row.agentName || 'Total'}</td>
                  <Td>{row.allocatedTickets}</Td>
                  <Td>{row.answeredTickets}</Td>
                  <Td>{row.messagesSent}</Td>
                  <Td>{row.messagesReceived}</Td>
                  <Td>{row.finishedTickets}</Td>
                  <Td>
                    {!!row.averageTimeToFirstResponse
                      ? DateUtils.durationToString(
                          row.averageTimeToFirstResponse,
                        )
                      : '---'}
                  </Td>
                  <Td>
                    {!!row.averageTimeToFinish
                      ? DateUtils.durationToString(row.averageTimeToFinish)
                      : '---'}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
};

export default CardChatAgentMetricsTable;
