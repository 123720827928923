import React from 'react'
import { useQuery } from 'react-query';
import { apiRoutes } from '../../constants/api-routes';
import { StatisticsService } from '../../services/statistics.service';
import CustomECharts from '../CustomECharts';
import { RFMGroupsEnum } from '../../types/RFMGroupsEnum';
import { rfmColors } from '../../constants/colors';
import { useTranslation } from 'react-i18next';
import { RFMHistoricalChartData } from '../../types/RFMHistoricalChartData';
import { MoneyUtils } from '../../utils/money.utils';

function mapRFMDataToChartData(rfmData: RFMHistoricalChartData) {
  let result: any = {
    month: rfmData.month,
    total: 0,
  }

  Object.entries(rfmData.summary).forEach(([rfmGroup, stats]) => {
    result[rfmGroup] = stats.customerCount;
    result['summary'] = rfmData.summary;
    result.total += stats.customerCount;
  });
  return result;
}

interface RFMHistoricalChartProps {
  maxRecency: number;
}

const RFMHistoricalChart = ({
  maxRecency,
}: RFMHistoricalChartProps) => {
  const { t } = useTranslation();
  const { data: rfmHistoricalData = [] } = useQuery(
    apiRoutes.getRFMAnalysisHistory(8, maxRecency),
    async () => {
      const { data } = await StatisticsService.getRFMAnalysisHistory(8, maxRecency);
      // sort by alphabetical
      data.sort((a, b) => a.month.localeCompare(b.month));
      return data;
    },
    {
      staleTime: 1000 * 60 * 60,
      select(data) {
        return data.map(mapRFMDataToChartData);
      }
    }
  );

  const dimensions = ['month', ...Object.values(RFMGroupsEnum), "total"];
  return (
    <CustomECharts
      chartWidth={"100%"}
      chartHeight="450px"
      option={{
        legend: {
          formatter: (name: string) => {
            return t(`enums.RFMGroup.${name}`);
          },
          orient: 'vertical',
          right: 0,
          top: 20,
        },
        tooltip: {},
        color: Object.entries(rfmColors).map(([key, value]) => value.secondary),
        dataset: {
          dimensions,
          source: rfmHistoricalData,
        },
        grid: {
          right: 200,
        },
        xAxis: {
          type: "category",
          data: rfmHistoricalData.map((data) => data.month),
        },
        yAxis: {},
        series: Object.values(RFMGroupsEnum).map((rfmGroup) => ({
          // UNCOMMENT THIS LINE TO SHOW BARS INSTEAD OF LINES
          type: "bar",
          stack: 'total',
          // type: "line",
          name: rfmGroup,
          yAxisIndex: 0,
          tooltip: {
            formatter: ({ seriesName, data }: any) => {
              return `
                <strong>${t(`enums.RFMGroup.${seriesName}`)}</strong>
                <br />
                <br />
                <strong>Clientes:</strong> ${data[seriesName]} (${Number(data[seriesName] / data.total * 100).toFixed(2)}%)
                <br />
                <strong>Total em compras médio:</strong> ${MoneyUtils.formatCurrency(data.summary[seriesName].avgMonetary)}
                <br />
                <strong>Frequência média:</strong> ${data.summary[seriesName].avgFrequency}x
              `
            }
          },
        }))
        ,
      }}
    />
  )
}

export default RFMHistoricalChart