import { AxiosResponse } from 'axios';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import { Message, MessageWithIncludes } from '../types/Message';

const listMessagesByConversation = async (
  conversationId: string,
  parge: number,
): Promise<AxiosResponse<Message[]>> => {
  return request.get(
    apiRoutes.listMessagesByConversation(conversationId, parge),
  );
};

export type SendMessageDto =
  | {
      text: string;
      conversationId?: string;
      recipientPhoneNumberId: string;
      tempId: string;
      file?: File;
      onProgress?: (progress: number) => void;
    }
  | {
      text: string;
      conversationId: string;
      recipientPhoneNumberId?: string;
      tempId: string;
      file?: File;
      onProgress?: (progress: number) => void;
    };

const sendMessage = async (
  sendMessageDto: SendMessageDto,
): Promise<AxiosResponse<MessageWithIncludes>> => {
  if (sendMessageDto.file) {
    return sendMedia({
      file: sendMessageDto.file!,
      conversationId: sendMessageDto.conversationId!,
      recipientPhoneNumberId: sendMessageDto.recipientPhoneNumberId!,
      tempId: sendMessageDto.tempId,
      onProgress: sendMessageDto.onProgress,
    });
  }

  return request.post(apiRoutes.sendMessage(), sendMessageDto);
};

export interface SendMessageTemplateDto {
  templateId: string;
  conversationId: string;
  templateArgs?: {
    [key: string]: string | undefined;
  };
}

const sendMessageTemplate = async (
  sendMessageTemplateDto: SendMessageTemplateDto,
): Promise<AxiosResponse<MessageWithIncludes>> => {
  return request.post(apiRoutes.sendMessageTemplate(), sendMessageTemplateDto);
};

export interface SendMessageTemplateByPhoneDto {
  templateName: string;
  recipientPhoneNumberId: string;
  recipientName: string;
  templateArgs: Record<string, string | undefined | null>;
}

const sendMessageTemplateByPhone = async (
  sendMessageTemplateByPhoneDto: SendMessageTemplateByPhoneDto,
): Promise<AxiosResponse<MessageWithIncludes>> => {
  return request.post(
    apiRoutes.sendMessageTemplateByPhone(),
    sendMessageTemplateByPhoneDto,
  );
};

export type DownloadMediaDto =
  | {
      mediaId: string;
      mediaName: string;
      fileKey?: string;
    }
  | {
      mediaId?: string;
      mediaName: string;
      fileKey: string;
    };
const downloadMedia = async (downloadMediaDto: DownloadMediaDto) => {
  return request.get(apiRoutes.downloadMedia(downloadMediaDto), {
    responseType: 'blob',
  });
};

export type SendMediaDto =
  | {
      recipientPhoneNumberId: string;
      conversationId?: string;
      file: File;
      tempId: string;
      onProgress?: (progress: number) => void;
    }
  | {
      conversationId: string;
      recipientPhoneNumberId?: string;
      file: File;
      tempId: string;
      onProgress?: (progress: number) => void;
    };

const sendMedia = async ({
  file,
  conversationId,
  recipientPhoneNumberId,
  tempId,
  onProgress,
}: SendMediaDto): Promise<AxiosResponse<MessageWithIncludes>> => {
  const data = new FormData();

  data.append('file', file);
  if (tempId) {
    data.append('tempId', tempId);
  }
  if (conversationId) {
    data.append('conversationId', conversationId);
  } else {
    data.append('recipientPhoneNumberId', recipientPhoneNumberId!);
  }

  return request.post(apiRoutes.sendMedia(), data, {
    timeout: 5 * 60 * 1000,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      if (onProgress) {
        onProgress(percentCompleted);
      }
    },
  });
};

export const MessagesService = {
  listMessagesByConversation,
  sendMessage,
  sendMessageTemplate,
  downloadMedia,
  sendMedia,
  sendMessageTemplateByPhone,
};
