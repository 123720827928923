import * as XLSX from 'xlsx';

async function getHeadersFromSheet(file: File): Promise<string[]> {
  let headers: string[] = [];
  if (file.type === 'text/csv') {
    headers = await getHeadersFromCsv(file);
  }
  if (
    [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ].includes(file.type)
  ) {
    headers = await getHeadersFromXlsx(file);
  }
  return headers;
}

async function getHeadersFromCsv(file: File): Promise<string[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function (e) {
      let headers = reader?.result
        ?.toString()
        .split('\n')[0]
        .replace(/;/g, ',')
        .split(',');
      headers = headers?.map((header) => header.replace(/"/g, ''));
      resolve(headers || []);
    };
  });
}

async function getHeadersFromXlsx(file: File): Promise<string[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = function (e) {
      const data = new Uint8Array(reader.result as ArrayBufferLike);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheet = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheet];
      const headers = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      })[0];
      resolve(headers as string[]);
    };
  });
}

async function fetchFile(fileUrl: string): Promise<File> {
  const response = await fetch(fileUrl);
  const type = response.headers.get('content-type');
  if (!type) throw new Error('Content-Type not found');

  const blob = await response.blob();

  const fileExtension = type.split('/')[1];
  const fileName = fileUrl.split('/').pop() || `file.${fileExtension}`;
  const file = new File([blob], fileName, { type });

  return file;
}

export const FileUtils = {
  getHeadersFromSheet: async (file: File) => getHeadersFromSheet(file),
  fetchFile: async (fileUrl: string) => fetchFile(fileUrl),
};
