import mixpanel from 'mixpanel-browser';

mixpanel.init('6cc8676f906e1181cbc8614621978200');

const env_check = process.env.REACT_APP_ENV === 'production';

type MixpanelEvents =
  // auth
  | 'login'
  // general
  | 'page-view'
  // auto-replies
  | 'create-auto-reply'
  // auto-sorting-options
  | 'create-auto-sorting-option'
  // conversation-categories
  | 'create-conversation-category'
  // conversations
  | 'update-conversation'
  // messages
  | 'send-message'
  | 'send-multiple-messages'
  // customers
  | 'filter-customers'
  | 'import-customers'
  // message-template
  | 'create-message-template'
  | 'click-template-suggestion'
  // campaigns
  | 'view-campaign-details'
  // company-defined-fields
  | 'create-company-defined-field'
  // orders
  | 'import-orders';

let actions = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (
    name: MixpanelEvents,
    props?: { [key: string]: string | undefined | boolean },
  ) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props: { [key: string]: any }) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
  reset: () => {
    if (env_check) mixpanel.reset();
  },
};

export const MixpanelService = actions;
