import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useId,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import * as yup from "yup";
import {
  GenerateMessageTemplateDto,
  MessageTemplatesService,
} from "../../../../services/message-templates.service";

const schema = yup
  .object({
    evento: yup.string().required(),
    valorDoCupom: yup.string().required(),
    codigoDoCupom: yup.string().required(),
    dataDeValidade: yup.string().required(),
  })
  .required();

interface GenerateMessageTemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onGenerateMessageTemplate: (content: string, promptThreadId: string) => void;
}

const GenerateMessageTemplateModal = ({
  onClose,
  isOpen,
  onGenerateMessageTemplate,
}: GenerateMessageTemplateModalProps) => {
  const generateMessageTemplate = useMutation(
    (generateMessageTemplateDto: GenerateMessageTemplateDto) =>
      MessageTemplatesService.generateMessageTemplate(
        generateMessageTemplateDto
      )
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const formId = useId();

  async function onSubmit(inputValue: any) {
    const { data } = await generateMessageTemplate.mutateAsync(inputValue);
    onGenerateMessageTemplate(data.content, data.promptThreadId);
    handleClose();
  }

  function resetState() {
    setValue("evento", "");
    setValue("valorDoCupom", "");
    setValue("codigoDoCupom", "");
    setValue("dataDeValidade", "");
  }

  function handleClose() {
    resetState();
    onClose();
  }
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Gerador de templates da Revi</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form 
            onSubmit={(e) => {
              e.stopPropagation();
              handleSubmit(onSubmit)(e);
            }} id={formId}>
            <Stack spacing={5}>
              <FormControl isRequired>
                <FormLabel>Evento</FormLabel>
                <Input
                  placeholder="Ex: Black Friday, Natal"
                  {...register("evento")}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Valor do cupom</FormLabel>
                <Input
                  placeholder="Ex: 10%, R$50"
                  {...register("valorDoCupom")}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Código do cupom</FormLabel>
                <Input
                  placeholder="Ex: BLACKFRIDAY, 10OFF"
                  {...register("codigoDoCupom")}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Validade</FormLabel>
                <Input
                  placeholder="Ex: 10/10/2024, 24 horas"
                  {...register("dataDeValidade")}
                />
              </FormControl>
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button variant="outline" mr={3} onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            isLoading={generateMessageTemplate.isLoading}
            form={formId}
            type="submit">
            Gerar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GenerateMessageTemplateModal;
