import { FlowNodeConditionBlock } from '../types/ReactFlowNode';

function extractEdgesFromFlow(flow: any) {
  const newEdges: any[] = [];

  flow.flowNodes.forEach((flowNode: any) => {
    flowNode.flowNodeButtons.forEach((button: any) => {
      if (!button.url && button.targetFlowNodeId) {
        newEdges.push({
          source: flowNode.id,
          sourceHandle: button.id,
          target: button.targetFlowNodeId,
          targetHandle: button.targetFlowNodeId,
          id: `reactflow__edge-${flowNode.id}${button.id}-${button.targetFlowNodeId}${button.targetFlowNodeId}`,
        });
      }
    });
    flowNode.flowNodeConditionBlocks.forEach(
      (conditionBlock: FlowNodeConditionBlock) => {
        if (conditionBlock.targetFlowNodeId) {
          newEdges.push({
            source: flowNode.id,
            sourceHandle: conditionBlock.id,
            target: conditionBlock.targetFlowNodeId,
            targetHandle: conditionBlock.targetFlowNodeId,
            id: `reactflow__edge-${flowNode.id}${conditionBlock.id}-${conditionBlock.targetFlowNodeId}${conditionBlock.targetFlowNodeId}`,
          });
        }
      },
    );
    if (flowNode.nextFlowNodeId) {
      newEdges.push({
        source: flowNode.id,
        sourceHandle: flowNode.id,
        target: flowNode.nextFlowNodeId,
        targetHandle: flowNode.nextFlowNodeId,
        id: `reactflow__edge-${flowNode.id}-${flowNode.id}-${flowNode.nextFlowNodeId}-${flowNode.nextFlowNodeId}`,
      });
    }
  });

  flow.flowTriggers.forEach((trigger: any) => {
    newEdges.push({
      source: flow.id,
      sourceHandle: trigger.id,
      target: trigger.targetFlowNodeId,
      targetHandle: trigger.targetFlowNodeId,
      id: `reactflow__edge-${flow.id}${trigger.id}-${trigger.targetFlowNodeId}${trigger.targetFlowNodeId}`,
    });
  });

  return newEdges;
}

function extractNodesFromFlow(flow: any) {
  const flowNodes = flow.flowNodes.map((flowNode: any) => {
    return {
      id: flowNode.id,
      type: flowNode.type,
      position: { x: flowNode.posX, y: flowNode.posY },
      data: {
        ...flowNode.data,
        buttons: flowNode.flowNodeButtons,
        flowNodeConditionBlocks: flowNode.flowNodeConditionBlocks,
      },
    };
  });

  return [
    ...flowNodes,
    {
      id: flow.id,
      type: 'trigger',
      position: { x: 150, y: 270 },
      data: {
        flowTriggers: flow.flowTriggers,
      },
    },
  ];
}

export const ReactFlowUtils = {
  extractEdgesFromFlow,
  extractNodesFromFlow,
};
