const useGetCrudText = (isCreating: boolean) => {
  const crudTitle = isCreating ? 'Criar' : 'Editar';
  const crudActionText = isCreating ? 'Criar' : 'Salvar';

  return {
    crudTitle,
    crudActionText,
  };
};

export default useGetCrudText;
