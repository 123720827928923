import { MoneyUtils } from './money.utils';

export type FunnelMetric = 'totalOrders' | 'totalOrdersValue' | 'roi';

function getFormattedFunnelValue(
  value?: number,
  metric?: FunnelMetric,
): string | number {
  if (metric === 'totalOrdersValue') {
    return MoneyUtils.formatCurrency(value || 0);
  }
  if (metric === 'roi') {
    return `${value || 0}x`;
  }
  return value || '-';
}

export const ChartUtils = {
  getFormattedFunnelValue: (
    value?: number,
    metric?: FunnelMetric,
  ): string | number => getFormattedFunnelValue(value, metric),
};
