import {
  Button,
  Card,
  CardBody,
  CardHeader,
  DrawerBody,
  Flex,
  FormControl,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { FaTrash } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import ButtonIcon from "../../../../../../../components/ButtonIcon";
import FormLabel from "../../../../../../../components/FormLabel";
import InputSwitchControlled from "../../../../../../../components/InputSwitchControlled";
import { colors } from "../../../../../../../constants/colors";
import { TriggerNodeData } from "../../../../../../../types/ReactFlowNode";

const flowTriggerSchema = yup
  .object({
    type: yup
      .string()
      .required("Tipo é obrigatório")
      .oneOf(["exact_match", "keyword_match"]),
    text: yup.string().required("Texto é obrigatório"),
    isDefault: yup.boolean().default(false),
  })
  .required();

const schema = yup
  .object({
    flowTriggers: yup.array().of(flowTriggerSchema),
  })
  .required();

export interface TriggerNodeEditorProps {
  data: TriggerNodeData;
  formId: string;
  onSaveNode: (data: any) => void;
}
const TriggerNodeEditor = ({
  data,
  formId,
  onSaveNode,
}: TriggerNodeEditorProps) => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      flowTriggers: data.flowTriggers || [],
    },
  } as any);
  const {
    fields: flowTriggerFields,
    append: appendflowTrigger,
    remove: removeflowTrigger,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "flowTriggers", // unique name for your Field Array
  });

  function handleChangeDefault(index: number) {
    flowTriggerFields.forEach((_, i) => {
      if (i !== index) {
        setValue(`flowTriggers.${i}.isDefault`, false);
      }
    });
  }

  function handleSaveNode(data: any) {
    const texts = new Set();
    for (const trigger of data.flowTriggers) {
      if (texts.has(trigger.text)) {
        toast({
          title: "Validação",
          description: "Cada trigger deve ter um valor único",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      texts.add(trigger.text);
    }
    onSaveNode(data);
  }

  return (
    <DrawerBody>
      <form onSubmit={handleSubmit(handleSaveNode)} id={formId}>
        <Flex flexDir={"column"} gap={3}>
          {flowTriggerFields.map((field, index) => (
            <Flex flexDir={"row"} alignItems={"center"} key={field.id}>
              <Card>
                <CardHeader paddingBottom={0}>Trigger {index + 1}</CardHeader>
                <CardBody flexDir={"column"} display="flex" gap={2}>
                  <FormControl
                    w="100%"
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <FormLabel width="100px">Tipo</FormLabel>
                    <Select
                      width="100%"
                      placeholder="Selecione um tipo"
                      {...register(`flowTriggers.${index}.type`)}
                    >
                      {[
                        {
                          value: "exact_match",
                          label: "Mensagem recebida é igual a",
                        },
                        {
                          value: "keyword_match",
                          label: "Mensagem contém a palavra",
                        },
                      ].map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                    <Text color={colors.danger} fontSize="xs">
                      {errors.flowTriggers?.[index]?.type && (
                        <span>{errors.flowTriggers[index].type.message}</span>
                      )}
                    </Text>
                  </FormControl>
                  <FormControl
                    w="100%"
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <FormLabel width="100px">Valor</FormLabel>
                    <Input
                      width="100%"
                      {...register(`flowTriggers.${index}.text`)}
                    />
                    <Text color={colors.danger} fontSize="xs">
                      {errors.flowTriggers?.[index]?.text && (
                        <span>{errors.flowTriggers[index].text.message}</span>
                      )}
                    </Text>
                  </FormControl>
                  <FormControl
                    w="100%"
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <FormLabel
                      width="100px"
                      tooltip="Acionado ao conectar um fluxo externo"
                    >
                      Default
                    </FormLabel>
                    <InputSwitchControlled
                      control={control}
                      onChange={(value) => {
                        handleChangeDefault(index);
                      }}
                      name={`flowTriggers.${index}.isDefault`}
                    />
                  </FormControl>
                </CardBody>
              </Card>
              {flowTriggerFields.length >= 1 && (
                <ButtonIcon
                  icon={<FaTrash fontSize="20px" color={colors.danger} />}
                  onClick={() => removeflowTrigger(index)}
                />
              )}
            </Flex>
          ))}
          <Button
            onClick={() => {
              appendflowTrigger({
                id: uuidv4(),
                type: null, // default value
                text: "",
              });
            }}
            width={"fit-content"}
          >
            + Adicionar trigger
          </Button>
        </Flex>
      </form>
    </DrawerBody>
  );
};

export default TriggerNodeEditor;
