import {
  MessageTemplate,
  MessageTemplateWithIncludes,
} from './../types/MessageTemplate.d';
import { AxiosResponse } from 'axios';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import { ButtonType } from '../types/ButtonTypeEnum';
import { GupshupTemplateType } from '../types/GupshupTemplateType';
import { CommunicationChannelEnum } from '../types/CommunicationChannelEnum';
import { MessageTemplateButton } from '../types/MessageTemplateButton';
import { MessageTemplateTypeEnum } from '../types/MessageTemplateType';
import PhoneQualityRating from '../types/PhoneQualityRating';
import MessageTemplateCard from '../types/MessageTemplateCard';

export interface ListMessageTemplateItem extends MessageTemplateWithIncludes {
  messageTemplateButtons: (Pick<MessageTemplateButton, 'text' | 'url'> & {
    type: ButtonType;
    url?: string;
  })[];
}

const showMessageTemplate = async (
  id: string,
): Promise<AxiosResponse<MessageTemplateWithIncludes>> => {
  return request.get(apiRoutes.showMessageTemplate(id));
};

const listMessageTemplates = async (): Promise<
  AxiosResponse<ListMessageTemplateItem[]>
> => {
  return request.get(apiRoutes.listMessageTemplates());
};

const showPhoneQualityRating = async (): Promise<
  AxiosResponse<PhoneQualityRating>
> => {
  return request.get(apiRoutes.showPhoneQualityRating());
};

export interface GenerateMessageTemplateDto {
  evento: string;
  valorDoCupom: string;
  codigoDoCupom: string;
  dataDeValidade: string;
}
const generateMessageTemplate = async (
  generateMessageTemplateDto: GenerateMessageTemplateDto,
): Promise<AxiosResponse<{ content: string; promptThreadId: string }>> => {
  return request.post(
    apiRoutes.generateMessageTemplate(),
    generateMessageTemplateDto,
  );
};

export interface RegenerateMessageTemplateDto {
  promptThreadId: string;
  promptText: string;
}
const regenerateMessageTemplate = async (
  regenerateMessageTemplateDto: RegenerateMessageTemplateDto,
): Promise<AxiosResponse<{ content: string; promptThreadId: string }>> => {
  return request.post(
    apiRoutes.regenerateMessageTemplate(),
    regenerateMessageTemplateDto,
  );
};

export interface Button {
  type: ButtonType;
  text: string;
  url?: string;
  offerCode?: string;
}

export interface TemplateCard {
  sampleText: string;
  body: string;
  buttons: Button[];
  cardIndex?: number;
}

export interface CreateMessageTemplateDto {
  elementName: string;
  templateType: GupshupTemplateType;
  isLimitedOffer?: boolean;
  limitedOfferText?: string;
  limitedOfferHasExpiration?: boolean;
  limitedOfferExpirationDate?: string;
  limitedOfferExpirationTime?: string;
  content: string;
  buttons?: Button[];
  cards?: TemplateCard[];
  files?: File[];
  footer?: string;
  messageTemplateSuggestionId?: string;
  communicationChannel: CommunicationChannelEnum;
  // whatsappTemplateCategory: WhatsappTemplateCategory;
  type: MessageTemplateTypeEnum;
}

export interface CreateMessageTemplateConvertedDto
  extends Omit<
      CreateMessageTemplateDto,
      'limitedOfferExpirationDate' | 'cards'
    >,
    Pick<MessageTemplateWithIncludes, 'mediaUrl' | 'mediaType'> {
  cards: MessageTemplateCard[];
  limitedOfferExpirationDate?: string;
  limitedOfferExpirationTime?: string;
}

const createMessageTemplate = async (
  createMessageTemplateDto: CreateMessageTemplateDto,
) => {
  const data = new FormData();

  data.append('elementName', createMessageTemplateDto.elementName);
  data.append('templateType', createMessageTemplateDto.templateType);
  data.append('type', createMessageTemplateDto.type);
  data.append(
    'communicationChannel',
    createMessageTemplateDto.communicationChannel,
  );
  data.append('content', createMessageTemplateDto.content);
  if (createMessageTemplateDto.isLimitedOffer) {
    data.append('isLimitedOffer', 'true');
    data.append(
      'limitedOfferText',
      createMessageTemplateDto.limitedOfferText ?? '',
    );
    if (createMessageTemplateDto.limitedOfferHasExpiration) {
      data.append('limitedOfferHasExpiration', 'true');
      const limitedOfferExpirationDate = new Date(
        `${createMessageTemplateDto.limitedOfferExpirationDate}T${createMessageTemplateDto.limitedOfferExpirationTime}`,
      );
      data.append(
        'limitedOfferExpirationDate',
        limitedOfferExpirationDate.toISOString(),
      );
    }
  }
  if (
    createMessageTemplateDto.templateType === GupshupTemplateType.CAROUSEL &&
    !!createMessageTemplateDto.cards &&
    createMessageTemplateDto.cards.length > 0
  ) {
    data.append(
      'cards',
      JSON.stringify(
        createMessageTemplateDto.cards.map((card, index) => {
          card.sampleText = card.body;
          card.cardIndex = index;
          return card;
        }),
      ),
    );
  }
  if (!!createMessageTemplateDto.footer) {
    data.append('footer', createMessageTemplateDto.footer);
  }
  if (
    createMessageTemplateDto.templateType !== GupshupTemplateType.CAROUSEL &&
    !!createMessageTemplateDto.buttons
  ) {
    data.append('buttons', JSON.stringify(createMessageTemplateDto.buttons));
  }
  if (!!createMessageTemplateDto.files) {
    createMessageTemplateDto.files.forEach((file, index) => {
      data.append('files', file);
    });
  }

  if (!!createMessageTemplateDto.messageTemplateSuggestionId) {
    data.append(
      'messageTemplateSuggestionId',
      createMessageTemplateDto.messageTemplateSuggestionId,
    );
  }

  return request.post(apiRoutes.createMessageTemplate(), data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 60000,
  });
};

export interface UpdateMessageTemplateDto {
  id: string;
  ctaLink?: string | null;
  file?: File | null;
}

const updateMessageTemplate = async (
  updateMessageTemplateDto: UpdateMessageTemplateDto,
) => {
  const data = new FormData();

  if (!!updateMessageTemplateDto.file) {
    data.append('file', updateMessageTemplateDto.file);
  }

  if (!!updateMessageTemplateDto.ctaLink) {
    data.append('ctaLink', updateMessageTemplateDto.ctaLink);
  }

  return request.put(
    apiRoutes.updateMessageTemplate(updateMessageTemplateDto.id),
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 60000,
    },
  );
};

const deleteMessageTemplate = async (
  messageTemplateId: string,
): Promise<AxiosResponse<MessageTemplate>> => {
  return request.delete(apiRoutes.deleteMessageTemplate(messageTemplateId));
};

const getMessageTemplateCreateDto = async (
  id: string,
): Promise<AxiosResponse<CreateMessageTemplateConvertedDto>> => {
  return request.get(apiRoutes.getMessageTemplateCreateDto(id));
};

export const MessageTemplatesService = {
  showMessageTemplate,
  listMessageTemplates,
  createMessageTemplate,
  generateMessageTemplate,
  regenerateMessageTemplate,
  updateMessageTemplate,
  deleteMessageTemplate,
  showPhoneQualityRating,
  getMessageTemplateCreateDto,
};
