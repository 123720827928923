import { RFMGroupsEnum } from '../types/RFMGroupsEnum';

export const colors = {
  white: 'white',
  background: '#FFFFFF',
  primaryMedium: '#0726d9',
  primary: '#001594',
  primaryLight: '#4c65f1',
  secondary: '#ffbe1b',
  green: '#00ff6a',
  greenMedium: '#018f3c',
  blueTurquose: '#0afcea',
  blueLight: '#e0e5fa',
  danger: '#ff6567',
  red: 'red',
  lightRed: '#ff6567',
  tulip: '#ff8f90',
  black: 'black',
  darkGrey: '#6E6E6E',
  lightGrey: '#F1F1F1',
  middleGrey: '#c0c0c0',
  fontDark: '#1F1F1F',
  fontLight: 'white',
  fontlightGrey: '#6E6E6E',
  orange: 'orange',
  hyperlink: '#007bff',
  purple: '#805AD5',
  yellow: '#FFD700',
  yellowMedium: '#f7c82d',
  status: {
    inProgress: 'yellow',
    completed: 'green',
    scheduled: 'purple',
    failed: 'red',
    canceled: 'gray',
    interrupted: 'red',
    rejected: 'red',
    approved: 'green',
    pending: 'yellow',
    disabled: 'gray',
    paused: 'gray',
    deleted: 'red',
  },
  chartColors: {
    lightGreen: {
      primary: '#28c376',
      secondary: '#E7F5E9',
    },
    green: {
      primary: '#368855',
      secondary: '#C8E6C8',
    },
    lightBlue: {
      primary: '#77bdf7',
      secondary: '#E3F2FD',
    },
    orange: {
      primary: '#c95815',
      secondary: '#FFE5BD',
    },
    blue: {
      primary: '#1fa9e8',
      secondary: '#B2E5FB',
    },
    yellow: {
      primary: '#ddb425',
      secondary: '#FFFCCD',
    },
    darkBlue: {
      primary: '#4a83a8',
      secondary: '#C4E2F8',
    },
    red: {
      primary: '#e72726',
      secondary: '#FFCDD2',
    },
    darkOrange: {
      primary: '#f17652',
      secondary: '#FFCCBC',
    },
    grey: {
      primary: '#939dad',
      secondary: '#EFF0F3',
    },
    darkGrey: {
      primary: '#c2c2c2',
      secondary: '#7c7b7b',
    },
    purple: {
      primary: '#805AD5',
      secondary: '#D6BCFA',
    },
  },
};

export const rfmColors = {
  [RFMGroupsEnum.CHAMPIONS]: colors.chartColors.green,
  [RFMGroupsEnum.LOYAL_CUSTOMERS]: colors.chartColors.lightGreen,
  [RFMGroupsEnum.POTENTIAL_LOYALISTS]: colors.chartColors.blue,
  [RFMGroupsEnum.RECENT_CUSTOMERS]: colors.chartColors.darkOrange,
  [RFMGroupsEnum.PROMISING]: colors.chartColors.lightBlue,
  [RFMGroupsEnum.NEED_ATTENTION]: colors.chartColors.yellow,
  [RFMGroupsEnum.ABOUT_TO_SLEEP]: colors.chartColors.darkBlue,
  [RFMGroupsEnum.CANNOT_LOSE_THEM]: colors.chartColors.red,
  [RFMGroupsEnum.AT_RISK]: colors.chartColors.orange,
  [RFMGroupsEnum.HIBERNATING]: colors.chartColors.grey,
  [RFMGroupsEnum.LOST_CUSTOMERS]: colors.chartColors.darkGrey,
};
