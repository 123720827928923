import { Card, CardBody, CardHeader, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import CustomECharts from '../../../../../components/CustomECharts';
import { apiRoutes } from '../../../../../constants/api-routes';
import { DateUtils } from '../../../../../utils/date.utils';
import {
  ReportsService,
  StartedSessionsByHourAndDayOfWeekReport,
} from '../../../../../services/reports.service';
import { colors } from '../../../../../constants/colors';

const colorByWeekDay: Record<string, string> = {
  sunday: colors.chartColors.red.secondary,
  monday: colors.chartColors.orange.secondary,
  tuesday: colors.chartColors.green.secondary,
  wednesday: colors.chartColors.blue.secondary,
  thursday: colors.chartColors.purple.secondary,
  friday: colors.chartColors.yellow.secondary,
  saturday: colors.chartColors.darkBlue.secondary,
};
interface CardStartedSessionsByHourAndDayOfWeekProps {
  startDate: Date;
  endDate: Date;
}

const CardStartedSessionsByHourAndDayOfWeek = ({
  startDate,
  endDate,
}: CardStartedSessionsByHourAndDayOfWeekProps) => {
  const { t } = useTranslation();

  const { data: startedSessionsByHourAndDayOfWeek = [], isLoading } = useQuery(
    apiRoutes.getStartedSessionsByHourAndDayOfWeekReport(startDate, endDate),
    async () => {
      const { data } =
        await ReportsService.getStartedSessionsByHourAndDayOfWeekReport(
          startDate,
          endDate,
        );
      return data;
    },
    {
      enabled:
        DateUtils.isValidDate(startDate) && DateUtils.isValidDate(endDate),
    },
  );

  const ordersByWeekDayAndHour = startedSessionsByHourAndDayOfWeek.map(
    (item: StartedSessionsByHourAndDayOfWeekReport) => [
      String(item.hour),
      item.weekDay,
      item.count,
    ],
  );

  const maxOrderCountByWeekdayAndHour =
    startedSessionsByHourAndDayOfWeek.reduce(
      (acc: number, item: any) => Math.max(acc, item.count),
      1,
    );

  function normalizeValue(value: number, maxValue: number = 50): number {
    return (value / maxOrderCountByWeekdayAndHour) * maxValue;
  }

  return (
    <Card>
      <CardHeader>
        <Center>
          <b>Número de atendimentos iniciados por horário e dia da semana</b>
        </Center>
      </CardHeader>
      <CardBody>
        <CustomECharts
          option={{
            legend: {},
            tooltip: {
              position: 'top',
              formatter: (params: any) => {
                const [hour, weekDay, count] = params.value;
                return `<strong>${t(
                  `weekdays.long.${weekDay}`,
                )} às ${hour}:00</strong> - ${count} atendimentos`;
              },
            },
            xAxis: {
              type: 'category',
              data: [
                ...new Set(
                  startedSessionsByHourAndDayOfWeek.map(
                    (item: any) => item.hour,
                  ),
                ),
              ],
              splitLine: {
                show: true,
              },
              axisLine: {
                show: false,
              },
              boundaryGap: false,
            },
            yAxis: {
              type: 'category',
              axisLabel: {
                formatter: (value: string) => t(`weekdays.long.${value}`),
              },
              data: [
                'saturday',
                'friday',
                'thursday',
                'wednesday',
                'tuesday',
                'monday',
                'sunday',
              ],
              splitLine: {
                show: true,
              },
              axisLine: {
                show: false,
              },
              boundaryGap: false,
            },
            series: [
              {
                type: 'scatter',
                data: ordersByWeekDayAndHour,
                itemStyle: {
                  color: function (curr: any) {
                    return colorByWeekDay[curr.value[1] as string];
                  },
                },
                symbolSize: function (params: any) {
                  return normalizeValue(params[2]);
                },
                animationDelay: function (idx: any) {
                  return idx * 5;
                },
              },
            ],
          }}
        />
      </CardBody>
    </Card>
  );
};

export default CardStartedSessionsByHourAndDayOfWeek;
