import { AxiosResponse } from 'axios';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import { UpdateAbandonedCartAutomationDto } from '../pages/AutomationsPage/BackgroundAutomationsPage/EditBackgroundAutomationPage';
import { Automation } from '../types/Automation';

interface ListAutomationItem extends Automation {
  filter?: {
    name: string;
  };
  messageTemplate: {
    name: string;
  };
}

const listAutomations = async (): Promise<
  AxiosResponse<ListAutomationItem[]>
> => {
  return request.get(apiRoutes.listAutomations());
};

export interface CreateAutomationDto {
  name: string;
  isActive: boolean;
  messageTemplateId: string;
  filterId: string;
  dailyMessageLimitOnWhatsapp: number;
  frequency: 'daily' | 'weekly' | 'monthly';
  daysOfWeek: number[];
  daysOfMonth: number[];
  hours: number;
  minutes: number;
  templateArgs?: Record<string, string | null | undefined>;
  isAutomationRepetitionAllowed: boolean;
  minDaysSinceLastCampaign: number;
}

export interface UpdateAutomationDto extends CreateAutomationDto {}

export interface AutomationFormValues
  extends Omit<CreateAutomationDto, 'daysOfWeek' | 'daysOfMonth'> {
  daysOfWeek: string[];
  daysOfMonth: string[];
  automationType: { slug: string };
}

const createAutomation = async (
  createAutomationDto: CreateAutomationDto,
): Promise<AxiosResponse<Automation>> => {
  return request.post(apiRoutes.createAutomation(), createAutomationDto);
};

const updateAutomation = async (
  automationId: string,
  updateAutomationDto: UpdateAutomationDto,
): Promise<AxiosResponse<Automation>> => {
  return request.put(
    apiRoutes.updateAutomation(automationId),
    updateAutomationDto,
  );
};

const updateAbandonedCartAutomation = async (
  automationId: string,
  updateAbandonedCartAutomationDto: UpdateAbandonedCartAutomationDto,
): Promise<AxiosResponse<Automation>> => {
  return request.put(
    apiRoutes.updateAbandonedCartAutomation(automationId),
    updateAbandonedCartAutomationDto,
  );
};

const deleteAutomation = async (
  automationId: string,
): Promise<AxiosResponse<Automation>> => {
  return request.delete(apiRoutes.deleteAutomation(automationId));
};

const toggleAutomation = async (
  automationId: string,
): Promise<AxiosResponse<Automation>> => {
  return request.patch(apiRoutes.toggleAutomation(automationId));
};

const showAutomation = async (
  automationId: string,
): Promise<AxiosResponse<Automation>> => {
  return request.get(apiRoutes.showAutomation(automationId));
};

export const AutomationsService = {
  listAutomations,
  createAutomation,
  updateAutomation,
  updateAbandonedCartAutomation,
  deleteAutomation,
  toggleAutomation,
  showAutomation,
};
