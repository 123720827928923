import { Flex, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { RefCallBack, UseFormRegisterReturn } from "react-hook-form";
import { colors } from "../../../../constants/colors";
import { useEffect, useRef, useState } from "react";

interface UrlButtonFieldProps {
  buttonTitleFormRegister: UseFormRegisterReturn<any>;
  buttonUrlFormRegister: UseFormRegisterReturn<any>;
  isTitleInvalid: boolean;
  isUrlInvalid: boolean;
  invalidTitleMessage?: string;
  invalidUrlMessage?: string;
  titleValue: string;
  urlValue: string;
}

function UrlButtonField({
  buttonTitleFormRegister,
  buttonUrlFormRegister,
  isTitleInvalid,
  isUrlInvalid,
  invalidTitleMessage,
  invalidUrlMessage,
  titleValue,
  urlValue,
}: UrlButtonFieldProps) {
  const titleInputRef = useRef<HTMLInputElement | null>(null);
  const urlInputRef = useRef<HTMLInputElement | null>(null);

  const [bluered, setBluered] = useState(false);

  useEffect(() => {
    if (bluered) return;
    if (titleInputRef.current) {
      titleInputRef.current?.blur();
    }
    if (urlInputRef.current) {
      urlInputRef.current?.blur();
    }
    setBluered(true);
  }, [titleInputRef, urlInputRef]);

  return (
    <Flex gap={2}>
      <FormControl w="250px">
        <FormLabel>Botão</FormLabel>
        <Input
          placeholder="Título do botão"
          {...buttonTitleFormRegister}
          value={titleValue}
          ref={(e) => {
            buttonTitleFormRegister.ref(e);
            titleInputRef.current = e;
          }}
          isInvalid={isTitleInvalid}
        />
        <Text color={colors.danger} fontSize="xs">
          {invalidTitleMessage}
        </Text>
      </FormControl>
      <FormControl w="300px">
        <FormLabel>URL</FormLabel>
        <Input
          placeholder="URL do botão"
          {...buttonUrlFormRegister}
          value={urlValue}
          ref={(e) => {
            buttonUrlFormRegister.ref(e);
            urlInputRef.current = e;
          }}
          onChange={(e) => {
            buttonUrlFormRegister.onChange(e);
            urlInputRef.current = e.target;
          }}
          isInvalid={isUrlInvalid}
        />
        <Text color={colors.danger} fontSize="xs">
          {invalidUrlMessage}
        </Text>
      </FormControl>
    </Flex>
  );
}

export default UrlButtonField;
