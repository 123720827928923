export default interface ReportOverviewData {
  openTickets: number;
  closedTickets: number;
  medianTicketFinishTime: number;
  medianTimeToFirstAgent: number;
  newFirstCustomerTicketsCount: number;
  openVsClosedTicketsByDate: OpenVsClosedTicketsByDate[];
  ticketsByCategory: TicketsByCategory[];
  ticketsStartedByClientVsCompanyByDate: TicketsStartedByClientVsCompanyByDate[];
  averageFirstResponseTimePeriods: AverageFirstResponseTimePeriods;
}

interface AverageFirstResponseTimePeriods {
  lessThan5m: number;
  between5mAnd15m: number;
  between15mAnd30m: number;
  between30mAnd1h: number;
  between1hAnd3h: number;
  between3hAnd8h: number;
  moreThan8h: number;
}
interface OpenVsClosedTicketsByDate {
  date: Date;
  openTickets: number;
  closedTickets: number;
}
interface TicketsStartedByClientVsCompanyByDate {
  date: Date;
  clientStarted: number;
  companyStarted: number;
}

interface TicketsByCategory {
  categoryName: string;
  ticketCount: number;
}

export function generateBlankReportOverviewData() {
  return {
    openTickets: 0,
    closedTickets: 0,
    medianTicketFinishTime: 0,
    medianTimeToFirstAgent: 0,
    newFirstCustomerTicketsCount: 0,
    openVsClosedTicketsByDate: [],
    ticketsByCategory: [],
    ticketsStartedByClientVsCompanyByDate: [],
    averageFirstResponseTimePeriods: {
      lessThan5m: 0,
      between5mAnd15m: 0,
      between15mAnd30m: 0,
      between30mAnd1h: 0,
      between1hAnd3h: 0,
      between3hAnd8h: 0,
      moreThan8h: 0,
    },
  } as ReportOverviewData;
}
