import { AxiosResponse } from 'axios';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import { ReportBufferData } from '../types/ReportBufferData';
import ReportOverviewData from '../types/ReportOverviewData';
import { DateUtils } from '../utils/date.utils';

export interface StartedSessionsByHourAndDayOfWeekReport {
  weekDay: number;
  hour: number;
  count: number;
}

export interface ChatAgentMetricsReport {
  agentId: string;
  agentName?: string;
  allocatedTickets: number;
  answeredTickets: number;
  messagesSent: number;
  messagesReceived: number;
  finishedTickets: number;
  averageTimeToFirstResponse: number;
  averageTimeToFinish: number;
}

const exportFlowClicksReport = async (
  flowId: string,
): Promise<AxiosResponse<ReportBufferData>> => {
  return request.get(apiRoutes.exportFlowClicksReport(flowId));
};

const getOverview = async (
  startDate: string,
  endDate: string,
): Promise<AxiosResponse<ReportOverviewData>> => {
  return request.get(apiRoutes.getReportOverview(startDate, endDate));
};

const exportCampaignSalesReport = async (
  startDate: string,
  endDate: string,
): Promise<AxiosResponse<ReportBufferData>> => {
  return request.get(apiRoutes.exportCampaignSalesReport(startDate, endDate), {
    timeout: 120000,
  });
};

const getStartedSessionsByHourAndDayOfWeekReport = async (
  startDate: Date,
  endDate: Date,
): Promise<AxiosResponse<StartedSessionsByHourAndDayOfWeekReport[]>> => {
  return await request.get(
    apiRoutes.getStartedSessionsByHourAndDayOfWeekReport(startDate, endDate),
  );
};

const getChatAgentMetricsReport = async (
  startDate: Date,
  endDate: Date,
): Promise<AxiosResponse<ChatAgentMetricsReport[]>> => {
  if (!DateUtils.isValidDate(startDate) || !DateUtils.isValidDate(endDate)) {
    Promise.reject('Invalid date');
  }

  return request.get(apiRoutes.getChatAgentMetricsReport(startDate, endDate));
};

export const ReportsService = {
  exportFlowClicksReport,
  getOverview,
  exportCampaignSalesReport,
  getStartedSessionsByHourAndDayOfWeekReport,
  getChatAgentMetricsReport,
};
