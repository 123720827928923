import { AxiosResponse } from 'axios';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import { AutomaticSortingOption } from '../types/AutomaticSortingOption';
import { FormDataUtils } from '../utils/form-data.utils';

const listAutoSortingOptions = async (): Promise<
  AxiosResponse<AutomaticSortingOption[]>
> => {
  return request.get(apiRoutes.listAutoSortingOptions());
};

export interface CreateAutoSortingOptionDto {
  conversationCategoryId: string;
  firstMessage: string;
  isActive: boolean;
  file?: File;
}

const createAutoSortingOption = async (
  createAutoSortingOptionDto: CreateAutoSortingOptionDto,
) => {
  const data = FormDataUtils.convertJsonToFormData({
    conversationCategoryId: createAutoSortingOptionDto.conversationCategoryId,
    firstMessage: createAutoSortingOptionDto.firstMessage,
    isActive: createAutoSortingOptionDto.isActive,
    file: createAutoSortingOptionDto.file,
  });

  return request.post(apiRoutes.createAutoSortingOption(), data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export interface UpdateAutoSortingOptionDto {
  autoSortingOptionId: string;
  conversationCategoryId?: string;
  firstMessage?: string;
  isActive: boolean;
  file?: File;
}

const updateAutoSortingOption = async (
  updateAutoSortingOptionDto: UpdateAutoSortingOptionDto,
) => {
  const data = FormDataUtils.convertJsonToFormData({
    conversationCategoryId: updateAutoSortingOptionDto.conversationCategoryId,
    firstMessage: updateAutoSortingOptionDto.firstMessage,
    isActive: updateAutoSortingOptionDto.isActive,
    file: updateAutoSortingOptionDto.file,
  });

  return request.put(
    apiRoutes.updateAutoSortingOption(
      updateAutoSortingOptionDto.autoSortingOptionId,
    ),
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

const deleteAutoSortingOption = async (autoSortingOptionId: string) => {
  return request.delete(apiRoutes.deleteAutoSortingOption(autoSortingOptionId));
};

export const AutoSortingOptionsService = {
  listAutoSortingOptions,
  createAutoSortingOption,
  updateAutoSortingOption,
  deleteAutoSortingOption,
};
