import { EngagementActionsEnum } from '../../../../types/EngagementActionsEnum';

export const selectEngamentActionOptions = [
  {
    value: EngagementActionsEnum.RECEIVED,
    label: 'Recebeu a mensagem',
  },
  {
    value: EngagementActionsEnum.READ,
    label: 'Leu a mensagem',
  },
  {
    value: EngagementActionsEnum.CLICKED,
    label: 'Clicou no link',
  },
  {
    value: EngagementActionsEnum.REPLIED,
    label: 'Respondeu',
  },
  {
    value: EngagementActionsEnum.NOT_REPLIED,
    label: 'Não respondeu',
  },
  {
    value: EngagementActionsEnum.NOT_CLICKED,
    label: 'Não clicou',
  },
  {
    value: EngagementActionsEnum.NOT_READ,
    label: 'Não leu',
  },
];
