const resetDate = (date: Date) => {
  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();
  return new Date(new Date(0).setUTCHours(hour, minute));
};

const isValidDate = (date: Date) => !isNaN(date.getTime());

const durationToString = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${hours}h ${minutes}m`;
};

export const DateUtils = {
  durationToString,
  isValidDate,
  resetDate,
};
