import {
  DownloadMediaDto,
  MessagesService,
} from '../services/messages.service';
import { ReportBufferData } from '../types/ReportBufferData';

const useDownloadFile = () => {
  function openFileInNewTab(url: string) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank'; // Opens the link in a new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up by removing the link after click
  }

  function downloadExcelReport(data: ReportBufferData, fileName: string) {
    const uint8Array = new Uint8Array(data.data);
    const blob = new Blob([uint8Array], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    downloadFileFromUrl(url, fileName);
  }

  function downloadFileFromUrl(url: string, fileName: string) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  function downloadWhatsappMedia({
    mediaId,
    mediaName,
    fileKey,
  }: DownloadMediaDto) {
    MessagesService.downloadMedia({
      mediaId: mediaId!,
      mediaName,
      fileKey: fileKey!,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFileFromUrl(url, mediaName);
    });
  }

  return {
    downloadFileFromUrl,
    downloadWhatsappMedia,
    openFileInNewTab,
    downloadExcelReport,
  };
};

export default useDownloadFile;
