import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListMessageTemplateItem } from '../services/message-templates.service';
import { WinningMetric } from '../types/CampaignExperiment';
import { CommunicationChannelEnum } from '../types/CommunicationChannelEnum';
import { MessageTemplateUtils } from '../utils/message-templates.utils';
import { RootState } from './store';

interface Variant {
  templateId: string;
  templateArgs: Record<string, string>;
  templateText: string;
}

interface CampaignCreationState {
  communicationChannel: CommunicationChannelEnum;
  selectedTemplate: null | ListMessageTemplateItem;
  selectedCustomerRows: Record<string, boolean>;
  showSelectCustomerRows: boolean;
  refetchKey: number;
  filterCriteria: null | string;
  scheduledExecutionTime: string | null;
  templateArgs: Record<string, string>;
  variants: Variant[];
  // AB Test
  winningMetric: WinningMetric;
  testSizePercentage: number;
  durationInMinutes: number;
}

const initialState: CampaignCreationState = {
  communicationChannel: CommunicationChannelEnum.WHATSAPP,
  selectedTemplate: null,
  selectedCustomerRows: {},
  showSelectCustomerRows: false,
  refetchKey: 0,
  filterCriteria: null,
  scheduledExecutionTime: null,
  templateArgs: {},
  variants: [],
  winningMetric: WinningMetric.ENGAGEMENT_RATE,
  testSizePercentage: 10,
  durationInMinutes: 60,
};

export const campaignCreationSlice = createSlice({
  name: 'campaignCreation',
  initialState,
  reducers: {
    updateCommunicationChannel: (
      state: CampaignCreationState,
      action: PayloadAction<CommunicationChannelEnum>,
    ) => {
      state.communicationChannel = action.payload;
      state.selectedTemplate = null;
      state.templateArgs = {};
      state.selectedCustomerRows = {};
      state.filterCriteria = null;
      state.variants = [];
      state.winningMetric = initialState.winningMetric;
      state.testSizePercentage = initialState.testSizePercentage;
      state.durationInMinutes = initialState.durationInMinutes;
    },
    updateSelectedMessageTemplate: (
      state: CampaignCreationState,
      action: PayloadAction<ListMessageTemplateItem | null>,
    ) => {
      state.selectedTemplate = action.payload;
      state.templateArgs = {};
      state.selectedCustomerRows = {};
      state.filterCriteria = null;
      state.variants = [];
      state.winningMetric = initialState.winningMetric;
      state.testSizePercentage = initialState.testSizePercentage;
      state.durationInMinutes = initialState.durationInMinutes;
    },
    setShowSelectCustomerRows: (
      state: CampaignCreationState,
      action: PayloadAction<boolean>,
    ) => {
      state.showSelectCustomerRows = action.payload;
    },
    setSelectedCustomerRows: (
      state: CampaignCreationState,
      action: PayloadAction<Record<string, boolean>>,
    ) => {
      state.selectedCustomerRows = action.payload;
    },
    updateRefetchKey: (state) => {
      state.refetchKey = Date.now();
    },
    setFilterCriteria: (
      state: CampaignCreationState,
      action: PayloadAction<string>,
    ) => {
      state.filterCriteria = action.payload;
    },
    setScheduledExecutionTime: (
      state: CampaignCreationState,
      action: PayloadAction<string | null>,
    ) => {
      state.scheduledExecutionTime = action.payload;
    },
    updateTemplateArg: (
      state: CampaignCreationState,
      action: PayloadAction<Record<string, string>>,
    ) => {
      state.templateArgs = { ...state.templateArgs, ...action.payload };
    },
    resetTemplateArgs: (state) => {
      state.templateArgs = {};
    },
    finishCampaignCreation: (state) => {
      Object.assign(state, initialState);
    },
    addVariant: (
      state: CampaignCreationState,
      action: PayloadAction<Variant>,
    ) => {
      state.variants.push(action.payload);
    },
    removeVariant: (
      state: CampaignCreationState,
      action: PayloadAction<number>,
    ) => {
      state.variants.splice(action.payload, 1);
    },
    updateVariantTemplateArgs: (
      state: CampaignCreationState,
      action: PayloadAction<{
        index: number;
        templateArgs: Record<string, string>;
      }>,
    ) => {
      state.variants[action.payload.index].templateArgs = {
        ...state.variants[action.payload.index].templateArgs,
        ...action.payload.templateArgs,
      };
    },
    updateTestSizePercentage: (
      state: CampaignCreationState,
      action: PayloadAction<number>,
    ) => {
      state.testSizePercentage = action.payload;
    },
    updateDurationInMinutes: (
      state: CampaignCreationState,
      action: PayloadAction<number>,
    ) => {
      state.durationInMinutes = action.payload;
    },
    updateWinningMetric: (
      state: CampaignCreationState,
      action: PayloadAction<WinningMetric>,
    ) => {
      state.winningMetric = action.payload;
    },
    cancelExperiment: (state: CampaignCreationState) => {
      state.variants = [];
      state.winningMetric = initialState.winningMetric;
      state.testSizePercentage = initialState.testSizePercentage;
      state.durationInMinutes = initialState.durationInMinutes;
    },
  },
});

export const isExperiment = (state: RootState) =>
  state.campaignCreation.variants.length > 0;

export const isValidCampaignCreationState = (state: RootState) => {
  const isExperiment = state.campaignCreation.variants.length > 0;
  let isValidExperiment = true;
  if (isExperiment) {
    const isValidVariations = state.campaignCreation.variants.every(
      (variant) => {
        return MessageTemplateUtils.isValidTemplateArgs(
          variant.templateArgs,
          variant.templateText,
        );
      },
    );

    isValidExperiment =
      isValidVariations &&
      state.campaignCreation.durationInMinutes > 0 &&
      state.campaignCreation.testSizePercentage > 0;
  }
  return (
    state.campaignCreation.communicationChannel !== null &&
    state.campaignCreation.selectedTemplate !== null &&
    Object.keys(state.campaignCreation.selectedCustomerRows).length > 0 &&
    MessageTemplateUtils.isValidTemplateArgs(
      state.campaignCreation.templateArgs,
      state.campaignCreation.selectedTemplate?.templateText,
    ) &&
    isValidExperiment
  );
};

export const {
  updateSelectedMessageTemplate,
  setShowSelectCustomerRows,
  updateCommunicationChannel,
  setSelectedCustomerRows,
  updateRefetchKey,
  updateTemplateArg,
  setScheduledExecutionTime,
  finishCampaignCreation,
  resetTemplateArgs,
  setFilterCriteria,
  addVariant,
  removeVariant,
  updateVariantTemplateArgs,
  updateTestSizePercentage,
  updateDurationInMinutes,
  updateWinningMetric,
  cancelExperiment,
} = campaignCreationSlice.actions;
export default campaignCreationSlice.reducer;
