import { apiRoutes } from './../constants/api-routes';
import { request } from '../constants/request';
import { Axios, AxiosResponse } from 'axios';

const getVtexConfig = async (): Promise<
  AxiosResponse<{
    vtexAccountName: string;
    vtexStoreDomain: string;
    isVtexActive: boolean;
  }>
> => {
  return request.get(apiRoutes.getVtexConfig());
};

export interface RegisterVtexOrderHookDto {
  vtexAccountName: string;
  vtexAppKey: string;
  vtexAppToken: string;
  vtexStoreDomain: string;
}

const registerVtexOrderHook = async (
  registerVtexOrderHookDto: RegisterVtexOrderHookDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(
    apiRoutes.registerVtexOrderHook(),
    registerVtexOrderHookDto,
  );
};

const getShopifyConfig = async (): Promise<
  AxiosResponse<{
    shopifyShopName: string;
    isShopifyActive: boolean;
  }>
> => {
  return request.get(apiRoutes.getShopifyConfig());
};

export interface SaveShopifyCredentialsDto {
  shopifyShopName: string;
  shopifyAdminAccessToken: string;
  shopifyApiKey: string;
  shopifyApiSecretKey: string;
}

const saveShopifyCredentials = async (
  saveShopifyCredentialsDto: SaveShopifyCredentialsDto,
) => {
  return request.post(
    apiRoutes.saveShopifyCredentials(),
    saveShopifyCredentialsDto,
  );
};

const getBlingConfig = async (): Promise<
  AxiosResponse<{
    isBlingActive: string;
    blingClientId: string;
  }>
> => {
  return request.get(apiRoutes.getBlingConfig());
};

export interface SaveBlingCredentialsDto {
  blingClientId: string;
  blingClientSecret: string;
}

const saveBlingCredentials = async (
  saveBlingCredentialsDto: SaveBlingCredentialsDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(
    apiRoutes.saveBlingCredentials(),
    saveBlingCredentialsDto,
  );
};

const getLojaIntegradaConfig = async (): Promise<
  AxiosResponse<{
    isLojaIntegradaActive: boolean;
  }>
> => {
  return request.get(apiRoutes.getLojaIntegradaConfig());
};

export interface registerLojaIntegradaOrderHookDto {
  lojaIntegradaApiKey: string;
}

const registerLojaIntegradaOrderHook = async (
  registerLojaIntegradaOrderHookDto: registerLojaIntegradaOrderHookDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(
    apiRoutes.registerLojaIntegradaOrderHook(),
    registerLojaIntegradaOrderHookDto,
  );
};

const getIntegrationStatusSummary = async (): Promise<
  AxiosResponse<{
    isShopifyActive: boolean;
    isVtexActive: boolean;
    isUnboxActive: boolean;
    isVisualECommerceActive: boolean;
    isLojaIntegradaActive: boolean;
    isBlingActive: boolean;
    isMagazordActive: boolean;
    isWooCommerceActive: boolean;
    isMagentoActive: boolean;
    isIngresseActive: boolean;
  }>
> => {
  return request.get(apiRoutes.getIntegrationStatusSummary());
};

export const IntegrationsService = {
  getVtexConfig,
  registerVtexOrderHook,
  getShopifyConfig,
  saveShopifyCredentials,
  getBlingConfig,
  saveBlingCredentials,
  getLojaIntegradaConfig,
  registerLojaIntegradaOrderHook,
  getIntegrationStatusSummary,
};
