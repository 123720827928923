import { ArrowLeftIcon } from "@chakra-ui/icons";
import { Box, Heading, IconButton, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SettingsLayout from "../SettingsLayout";

interface IntegrationSettingsLayoutProps {
  children: React.ReactNode;
  title: string;
}

const IntegrationSettingsLayout = ({
  children,
  title,
}: IntegrationSettingsLayoutProps) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();

  useEffect(() => {
    const authStatus = searchParams.get("authStatus");
    if (authStatus === "success") {
      toast({
        title: "Autenticação realizada com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      searchParams.delete("authStatus");
      setSearchParams(searchParams);
    } else if (authStatus === "error") {
      toast({
        title: "Erro ao autenticar",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      searchParams.delete("authStatus");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, toast]);

  return (
    <SettingsLayout>
      <Box padding='20px'>
        <Box display="flex" alignItems="center" gap={4} mb={4}>
          <IconButton
            aria-label="Voltar"
            icon={<ArrowLeftIcon />}
            onClick={() => navigate(-1)}
            size="sm"
            variant={'outline'}
          />
          <Heading size="md">{title}</Heading>
        </Box>
        {children}
      </Box>
    </SettingsLayout>
  );
};

export default IntegrationSettingsLayout;
