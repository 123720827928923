enum MessageTemplateStatusEnum2 {
  pending = 'pending',
  rejected = 'rejected',
  approved = 'approved',
  deleted = 'deleted',
  disabled = 'disabled',
  paused = 'paused',
}

export default MessageTemplateStatusEnum2;
