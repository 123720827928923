import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';

const syncShopifyOrders = async () => {
  return request.post(apiRoutes.syncShopifyOrders());
};

export const ShopifyService = {
  syncShopifyOrders,
};
