import emojiMartData, { EmojiMartData } from '@emoji-mart/data';
import { CompactEmoji } from 'emojibase';
import { capitalize } from 'lodash';

const DEFAULT_PATCH_OPTION_IGNORE_FAILED_LOCALIZATION = true;

interface PatchEmojiDataOptions {
  ignoreFailedLocalization?: boolean;
}

export const patchEmojiData = async (
  locale: string,
  options?: PatchEmojiDataOptions,
): Promise<EmojiMartData> => {
  const {
    ignoreFailedLocalization = DEFAULT_PATCH_OPTION_IGNORE_FAILED_LOCALIZATION,
  } = options || {};

  const emojiMartSet: EmojiMartData = emojiMartData as EmojiMartData;
  let localizedSet: Record<string, CompactEmoji> = {};

  try {
    const emojiBaseData: CompactEmoji[] = await import(
      `emojibase-data/${locale}/compact.json`
    );

    for (const emojiLocalized in emojiBaseData) {
      const emoji = emojiBaseData[emojiLocalized];
      const hexCode = emoji?.hexcode?.toLowerCase();
      if (!hexCode) continue;
      localizedSet[hexCode] = emoji;
    }
  } catch (error) {
    console.error(
      `Failed to load Emojibase data for locale ${locale}. Using default en data.`,
      error,
    );
    return emojiMartSet;
  }

  for (const emojiKey in emojiMartSet.emojis) {
    const emoji = emojiMartSet.emojis[emojiKey as string];
    const hexCode = emoji?.skins?.[0]?.unified?.toLowerCase();
    const localizedEmoji = localizedSet[hexCode.toLowerCase()];
    if (!localizedEmoji) {
      if (ignoreFailedLocalization) delete emojiMartSet.emojis[emojiKey];
      continue;
    }
    emoji.name = capitalize(localizedEmoji.label);
    emoji.keywords = localizedEmoji.tags || [];
  }

  return emojiMartSet;
};
