import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox,
  Box,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { DateUtils } from '../../../../utils/date.utils';
import { capitalize, noop } from 'lodash';
import ReactDatePicker from 'react-datepicker';
import { CompanyBusinessHours } from '../../../../services/companies.service';
import {
  DEFAULT_BUSINESS_HOUR_START_TIME,
  DEFAULT_BUSINESS_HOUR_END_TIME,
} from '../constants';

const weekdaysNames = [
  'domingo',
  'segunda-feira',
  'terça-feira',
  'quarta-feira',
  'quinta-feira',
  'sexta-feira',
  'sábado',
];

interface TimePickerProps {
  value?: Date;
  onChange?: (date: Date) => void;
}

const TimePicker = ({ value, onChange }: TimePickerProps) => (
  <Box width={'100px'}>
    <ReactDatePicker
      selected={value}
      dateFormat="HH:mm"
      timeIntervals={15}
      showTimeSelectOnly
      showTimeSelect
      wrapperClassName="date-picker-wrapper"
      onChange={onChange}
      placeholderText="---"
    />
  </Box>
);

interface BusinessHoursTableProps {
  businessHours?: CompanyBusinessHours[];
  onChange?: (data: CompanyBusinessHours[]) => void;
}

const BusinessHoursTable = ({
  businessHours = [],
  onChange = noop,
}: BusinessHoursTableProps) => {
  const toast = useToast();

  const handleChange = (
    {
      startTime = DEFAULT_BUSINESS_HOUR_START_TIME,
      endTime = DEFAULT_BUSINESS_HOUR_END_TIME,
    }: { startTime?: Date; endTime?: Date },
    weekday: string,
  ) => {
    startTime = DateUtils.resetDate(startTime);
    endTime = DateUtils.resetDate(endTime);

    const weekdayIndex = weekdaysNames.indexOf(weekday);
    const existingItem = businessHours.some(
      (hour) => hour.weekday === weekdayIndex,
    );

    if (
      existingItem &&
      (startTime.getHours() > endTime.getHours() ||
        (startTime.getHours() === endTime.getHours() &&
          startTime.getMinutes() >= endTime.getMinutes()))
    ) {
      toast({
        title: 'Erro',
        description:
          'O horário de início deve ser menor que o horário de término.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!existingItem) {
      if (startTime >= endTime) {
        endTime = new Date(startTime.getTime() + 60 * 60 * 1000); // Add 1 hour to start time
      } else if (endTime <= startTime) {
        startTime = new Date(endTime.getTime() - 60 * 60 * 1000); // Subtract 1 hour from end time
      }
    }

    const updatedBusinessHours = existingItem
      ? businessHours.map((hour) =>
          hour.weekday === weekdayIndex
            ? { ...hour, startTime, endTime }
            : hour,
        )
      : [...businessHours, { weekday: weekdayIndex, startTime, endTime }];
    onChange(updatedBusinessHours);
  };

  const handleCheckboxChange = (checked: boolean, weekday: string) => {
    const weekdayIndex = weekdaysNames.indexOf(weekday);
    const updatedBusinessHours = checked
      ? [
          ...businessHours,
          {
            weekday: weekdayIndex,
            startTime: DEFAULT_BUSINESS_HOUR_START_TIME,
            endTime: DEFAULT_BUSINESS_HOUR_END_TIME,
          },
        ]
      : businessHours.filter((hour) => hour.weekday !== weekdayIndex);
    onChange(updatedBusinessHours);
  };

  const handleAllCheckboxChange = (checked: boolean) => {
    const updatedBusinessHours = checked
      ? [
          ...businessHours,
          ...weekdaysNames
            .map((_, index) => ({
              weekday: index,
              startTime: DEFAULT_BUSINESS_HOUR_START_TIME,
              endTime: DEFAULT_BUSINESS_HOUR_END_TIME,
            }))
            .filter(
              (newHour) =>
                !businessHours.some((hour) => hour.weekday === newHour.weekday),
            ),
        ]
      : [];
    onChange(updatedBusinessHours);
  };

  return (
    <TableContainer className="business-hours-table">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>
              <Checkbox
                isChecked={businessHours.length === weekdaysNames.length}
                onChange={(event) =>
                  handleAllCheckboxChange(event.target.checked)
                }
              />
            </Th>
            <Th>Dia da semana</Th>
            <Th>Horários</Th>
          </Tr>
        </Thead>
        <Tbody>
          {weekdaysNames.map((weekday, index) => {
            const weekdayHours = businessHours.find(
              (hour) => hour.weekday === index,
            );
            const startTime = weekdayHours?.startTime
              ? new Date(weekdayHours.startTime)
              : undefined;
            const endTime = weekdayHours?.endTime
              ? new Date(weekdayHours.endTime)
              : undefined;

            return (
              <Tr key={weekday}>
                <Td>
                  <Checkbox
                    isChecked={!!weekdayHours}
                    onChange={(event) =>
                      handleCheckboxChange(event.target.checked, weekday)
                    }
                  />
                </Td>
                <Td>{capitalize(weekday)}</Td>
                <Td>
                  <Flex gap={4} alignItems={'center'}>
                    <TimePicker
                      value={startTime}
                      onChange={(date) =>
                        handleChange({ startTime: date, endTime }, weekday)
                      }
                    />
                    <Box>até</Box>
                    <TimePicker
                      value={endTime}
                      onChange={(date) =>
                        handleChange({ startTime, endTime: date }, weekday)
                      }
                    />
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default BusinessHoursTable;
