import { differenceInMinutes } from 'date-fns';

const MINUTES_IN_ONE_DAY = 24 * 60;
function isSessionExpired(date: Date): boolean {
  const maxSessionSpanInMinutes = 1 * MINUTES_IN_ONE_DAY - 5;
  return differenceInMinutes(new Date(), date) >= maxSessionSpanInMinutes;
}

export const WhatsappValidationUtils = {
  isSessionExpired: (date: Date) => isSessionExpired(date),
};
