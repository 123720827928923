import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AuthService } from '../services/auth.service';
import { MixpanelService } from '../services/mixpanel.service';
import { JwtPayload, parseJwt } from '../utils/parse-jwt.utils';
import { RootState } from './store';

interface AuthState {
  currentUser: JwtPayload | null;
}

export const login = createAsyncThunk(
  'auth/login',
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await AuthService.login({ email, password });
      return response.data;
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  },
);

// Define the initial state using that type
const initialState: AuthState = {
  currentUser: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    checkAuthentication: (state) => {
      const accessToken = AuthService.getAccessToken();
      if (accessToken) {
        state.currentUser = parseJwt(accessToken);
      } else {
        state.currentUser = null;
      }
    },
    logout: (state) => {
      AuthService.logout();
      state.currentUser = null;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(login.fulfilled, (state, action) => {
      const { access_token: accessToken } = action.payload;

      localStorage.setItem('access_token', accessToken);
      const currentUser = parseJwt(accessToken);
      state.currentUser = currentUser;
      MixpanelService.identify(currentUser!.sub);
      MixpanelService.people.set({
        $email: currentUser!.email,
        companyId: currentUser!.companyId,
        companyName: currentUser!.company.name,
      });
      MixpanelService.track('login');
    });
  },
});

export const { checkAuthentication, logout } = authSlice.actions;

export const isAuthenticated = (state: RootState) =>
  state.auth.currentUser !== null;

export default authSlice.reducer;
