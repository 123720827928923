import { useTranslation } from "react-i18next";
import { NodeProps } from "reactflow";
import { TimeDelayNodeData } from "../../../../../../types/ReactFlowNode";
import NodeLayout from "../NodeLayout";
import { colors } from "../../../../../../constants/colors";
import { Box } from "@chakra-ui/react";
import { IoIosTimer } from "react-icons/io";

const TimeDelayNode = (
  props: NodeProps<TimeDelayNodeData>
) => {
  const { t } = useTranslation();

  return (
    <NodeLayout
      id={props.id}
      title={t(`enums.FlowNodeType.${props.type}`)}
      icon={<IoIosTimer />}
      color={colors.middleGrey}
    >
      <Box
        mt="20px"
        width="100%"
        borderRadius={"10px"}
        bgColor={"#F3F3F3"}
        padding="15px"
        height="fit-content">
        {props.data.quantity} {props.data.unit ? t(`enums.TimeDelayUnit.${props.data.unit}`) : ""}
      </Box>
    </NodeLayout>
  );
};

export default TimeDelayNode;
