import {
  Box,
  Flex,
  Heading,
  Divider,
  Button,
  useToast,
  FormLabel,
  Textarea,
} from '@chakra-ui/react';
import BusinessHoursTable from './components/BusinessHoursTable';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { apiRoutes } from '../../../constants/api-routes';
import {
  CompaniesService,
  CompanyBusinessHours,
  CompanyBusinessHoursDto,
} from '../../../services/companies.service';
import LoadingScreen from '../../../components/LoadingScreen';

const BusinessHoursSettingsPage = () => {
  const toast = useToast();
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [businessHoursData, setBusinessHoursData] = useState<
    CompanyBusinessHoursDto['businessHours']
  >([]);
  const [afterHoursMessage, setAfterHoursMessage] =
    useState<CompanyBusinessHoursDto['afterHoursMessage']>(null);

  const {
    data: getCompanyBusinessHoursResponse,
    isLoading,
    refetch,
  } = useQuery(
    apiRoutes.getCompanyBusinessHours(),
    CompaniesService.getCompanyBusinessHours,
    {
      onSuccess: (response) => {
        setBusinessHoursData(response.data.businessHours);
        setAfterHoursMessage(response.data.afterHoursMessage);
      },
    },
  );

  const updateCompanyBusinessHoursMutation = useMutation(
    CompaniesService.updateCompanyBusinessHours,
    {
      onSuccess: () => {
        setHasChanges(false);
        refetch();
      },
    },
  );

  const handleSaveClick = () => {
    if (!hasChanges) return;
    const promise = updateCompanyBusinessHoursMutation.mutateAsync({
      businessHours: businessHoursData,
      afterHoursMessage: afterHoursMessage,
    });

    toast.promise(promise, {
      loading: {
        title: 'Salvando',
        description: 'Salvando horário de atendimento...',
        duration: 9000,
        isClosable: true,
      },
      success: {
        title: 'Sucesso',
        description: 'Horário de atendimento salvo com sucesso.',
        duration: 5000,
        isClosable: true,
      },
      error: {
        title: 'Erro',
        description: 'Ocorreu um erro ao salvar o horário de atendimento.',
        duration: 5000,
        isClosable: true,
      },
    });
  };

  const handleBusinessHoursDataChange = (data: CompanyBusinessHours[]) => {
    setHasChanges(true);
    setBusinessHoursData(data);
  };

  const handleAfterHoursMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setHasChanges(true);
    setAfterHoursMessage(event.target.value);
  };

  const handleCancelClick = () => {
    if (!hasChanges) return;
    setBusinessHoursData(
      getCompanyBusinessHoursResponse?.data.businessHours || [],
    );
    setAfterHoursMessage(
      getCompanyBusinessHoursResponse?.data.afterHoursMessage || null,
    );
    setHasChanges(false);
  };

  return (
    <LoadingScreen isLoading={isLoading}>
      <Box padding="20px 40px">
        <Flex
          width="100%"
          justifyContent="space-between"
          paddingBottom={'16px'}
        >
          <Heading>Horário de Atendimento</Heading>
          <Flex gap={2}>
            <Button
              variant="outline"
              onClick={handleCancelClick}
              disabled={!hasChanges}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={handleSaveClick}
              disabled={!hasChanges}
            >
              Salvar
            </Button>
          </Flex>
        </Flex>
        <Divider orientation="horizontal" mt={2} />
        <Flex direction="column" gap={8}>
          <BusinessHoursTable
            businessHours={businessHoursData || []}
            onChange={handleBusinessHoursDataChange}
          />
          <Box>
            <FormLabel>Resposta automática</FormLabel>
            <Textarea
              placeholder="Digite aqui uma mensagem aqui"
              value={afterHoursMessage || undefined}
              onChange={handleAfterHoursMessageChange}
            />
          </Box>
        </Flex>
      </Box>
    </LoadingScreen>
  );
};

export default BusinessHoursSettingsPage;
