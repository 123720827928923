import { Grid, GridItem } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import SidebarSecondary from '../../components/SidebarSecondary';
import { appPaths } from '../../constants/app-paths';

interface SettingsLayoutProps {
  children: ReactNode;
}

const SIDEBAR_OPTIONS = [
  {
    title: 'Geral',
    path: appPaths.settings.general(),
  },
  {
    title: 'Colunas customizadas',
    path: appPaths.settings.customColumns(),
  },
  {
    title: 'Tags',
    path: appPaths.settings.tags(),
  },
  {
    title: 'Configurações de conta',
    path: appPaths.settings.accountSettings(),
  },
  {
    title: 'Integrações',
    path: appPaths.settings.integrationSettings.index(),
  },
  {
    title: 'Respostas rápidas',
    path: appPaths.settings.quickReplies(),
  },
  {
    title: 'Horário de Atendimento',
    path: appPaths.settings.businessHours(),
  },
  {
    title: 'Faturas',
    path: appPaths.settings.invoices(),
  },
];

const SettingsLayout = ({ children }: SettingsLayoutProps) => {
  return (
    <Grid
      height={'100vh'}
      templateColumns="250px auto"
      templateAreas={'"sidebar page"'}
    >
      <GridItem area="sidebar">
        <SidebarSecondary title="Configurações" options={SIDEBAR_OPTIONS} />
      </GridItem>
      <GridItem area="page" maxH={'100vh'} height={'100vh'} overflow="auto">
        {children}
      </GridItem>
    </Grid>
  );
};

export default SettingsLayout;
