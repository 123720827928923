import { FormDataUtils } from '../utils/form-data.utils';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import { AutomaticReplyCondition } from '../types/AutomaticReplyCondition';
import { AxiosResponse } from 'axios';
import { AutomaticReply } from '../types/AutomaticReply';

const listAutoReplies = async (): Promise<AxiosResponse<AutomaticReply[]>> => {
  return request.get(apiRoutes.listAutoReplies());
};

export interface CreateAutoReplyDto {
  keyword: string;
  message: string;
  condition: AutomaticReplyCondition;
  conversationCategoryId?: string;
  file?: File;
}

const createAutoReply = async (createAutoReplyDto: CreateAutoReplyDto) => {
  const data = FormDataUtils.convertJsonToFormData(createAutoReplyDto);
  return request.post(apiRoutes.createAutoReply(), data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export interface UpdateAutoReplyDto {
  id: string;
  keyword?: string;
  message?: string;
  condition?: AutomaticReplyCondition;
  conversationCategoryId?: string;
}
const updateAutoReply = async (updateAutoReplyDto: UpdateAutoReplyDto) => {
  const { id, ...updateData } = updateAutoReplyDto;
  const data = FormDataUtils.convertJsonToFormData(updateData);
  return request.put(apiRoutes.updateAutoReply(id), data);
};

const deleteAutoReply = async (autoReplyId: string) => {
  return request.delete(apiRoutes.deleteAutoReply(autoReplyId));
};

export const AutoRepliesService = {
  listAutoReplies,
  createAutoReply,
  updateAutoReply,
  deleteAutoReply,
};
