import { AxiosResponse } from 'axios';
import { request } from '../constants/request';
import { Invoice } from '../types/Invoice';
import { apiRoutes } from './../constants/api-routes';

const listInvoices = async (): Promise<AxiosResponse<Invoice[]>> => {
  return request.get(apiRoutes.listInvoices());
};

export const InvoicesService = {
  listInvoices,
};
