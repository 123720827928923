import {
  Box,
  DrawerBody,
  Flex,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Select,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { colors } from "../../../../../../../constants/colors";
import { TimeDelayNodeData } from "../../../../../../../types/ReactFlowNode";

const schema = yup
  .object({
    quantity: yup
      .number()
      .typeError("Campo obrigatório")
      .required("Campo obrigatório")
      .positive("Precisa ser um número positivo")
      .integer("Campo obrigatório"),
      unit: yup.string().oneOf(["minutes", "hours"]),
  })
  .required();

export interface TimeDelayEditorProps {
  data: TimeDelayNodeData;
  formId: string;
  onSaveNode: (data: any) => void;
}
const TimeDelayNodeEditor = ({
  data,
  formId,
  onSaveNode,
}: TimeDelayEditorProps) => {
  const { quantity, unit } = data;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      quantity: quantity || 0,
      unit,
    },
  });

  return (
    <DrawerBody>
      <Box marginBottom={4}>
        <Text>Atrase a próxima ação do fluxo em até 24h.</Text>
      </Box>
      <form onSubmit={handleSubmit(onSaveNode)} id={formId}>
          <FormLabel>Delay</FormLabel>
        <Flex gap={3}>
          <FormControl>
            <Controller
              name="quantity"
              control={control}
              render={({ field }) => (
                <NumberInput
                  placeholder="Delay"
                  {...field}
                  isInvalid={!!errors.quantity}
                >
                  <NumberInputField {...field}/>
                </NumberInput>
              )}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.quantity?.message}
            </Text>
          </FormControl>
          <FormControl>
            <Select
              {...register("unit")}>
              <option key={"minutes"} value="minutes">
                Minutos
              </option>
              <option key={"hours"} value="hours">
                Horas
              </option>
            </Select>
            <Text color={colors.danger} fontSize="xs">
              {errors.unit?.message}
            </Text>
          </FormControl>
        </Flex>
      </form>
    </DrawerBody>
  );
};

export default TimeDelayNodeEditor;
