import {
  Box,
  Card,
  CardBody,
  Divider,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { Th } from "../../../components/CustomTable";
import { apiRoutes } from "../../../constants/api-routes";
import { colors } from "../../../constants/colors";
import { CompaniesService } from "../../../services/companies.service";
import { BillingSettings } from "../../../types/BillingSettings";
import { MoneyUtils } from "../../../utils/money.utils";
import TableInvoices from "../IntegrationSettingsPage/BlingIntegrationPage/components/TableInvoices";

const InvoicesPage = () => {
  const { data: billingSettings } = useQuery(
    apiRoutes.getCompanyBillingSettings(),
    async () => {
      const { data } = await CompaniesService.getCompanyBillingSettings();
      return data;
    }
  )

  function calculateFixedCost(billingSettings?: BillingSettings) {
    if (!billingSettings) {
      return;
    }
    const total = billingSettings.customerServiceFee +
      billingSettings.platformFee +
      (billingSettings.whatsappMarketingPackageLimit || 0) * (billingSettings.whatsappMarketingMessageFee || 0) +
      (billingSettings.whatsappUtilityPackageLimit || 0) * (billingSettings.whatsappUtilityMessageFee || 0) +
      (billingSettings.whatsappServicePackageLimit || 0) * (billingSettings.whatsappServiceMessageFee || 0) +
      (billingSettings.smsPackageLimit || 0) * (billingSettings.smsMessageFee || 0);
    return total;
  }

  return (
    <Flex flexDir="column" padding="20px" gap={5}>
      <Heading size="lg">Financeiro</Heading>
      <Flex width="100%" gap={5}>
        <Card flex={3}>
          <CardBody>
            <Heading size="md" mb={3} color={colors.primary}>
              Cadastro financeiro
            </Heading>
            <Flex justifyContent={"space-between"}>
              <Box>
                <Box fontWeight="bold">Método de pagamento:</Box>
                <Box>{billingSettings?.paymentMethod}</Box>
              </Box>
              <Box>
                <Box fontWeight="bold">Razão social:</Box>
                <Box>{billingSettings?.company?.razaoSocial}</Box>
              </Box>
              <Box>
                <Box fontWeight="bold">CNPJ:</Box>
                <Box>{billingSettings?.company?.cnpj}</Box>
              </Box>
              <Box>
                <Box fontWeight="bold">Email de cobrança:</Box>
                <Box>{billingSettings?.billingContactEmail}</Box>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
      <Card>
        <CardBody>
          <Heading size="md" mb={4} color={colors.primary}>
            Pacote contratado
          </Heading>
          <Table variant="stripped" size="sm">
            <Thead>
              <Tr>
                <Th fontSize="small" fontWeight={"bold"}>
                  Fixo
                </Th>
                <Th fontSize="small">Marketing</Th>
                <Th fontSize="small">Utilidade</Th>
                <Th fontSize="small">Atendimento</Th>
                <Th fontSize="small">SMS</Th>
                <Th fontSize="small">Mensalidade</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td lineHeight={"20px"} verticalAlign="text-top">
                  <Text fontWeight="bold" as="span">
                    Plataforma:
                  </Text>{" "}
                  {MoneyUtils.formatCurrency(billingSettings?.platformFee)}
                  <br />
                  <Text fontWeight="bold" as="span">
                    Time CS:
                  </Text>{" "}
                  {MoneyUtils.formatCurrency(billingSettings?.customerServiceFee)}
                </Td>

                <Td lineHeight={"20px"} verticalAlign="text-top">
                  <Text fontWeight="bold" as="span">
                    Disparos:
                  </Text>{" "}
                  {billingSettings?.whatsappMarketingPackageLimit}<br />
                  <Text fontWeight="bold" as="span">
                    Pacote:
                  </Text>{" "}
                  {MoneyUtils.formatCurrency(billingSettings?.whatsappMarketingMessageFee)}
                  <br />
                  <Text fontWeight="bold" as="span">
                    Excedente:
                  </Text>{" "}
                  {MoneyUtils.formatCurrency(billingSettings?.whatsappMarketingExtraMessageFee)}
                </Td>

                <Td lineHeight={"20px"} verticalAlign="text-top">
                  <Text fontWeight="bold" as="span">
                    Disparos:
                  </Text>{" "}
                  {billingSettings?.whatsappUtilityPackageLimit}<br />
                  <Text fontWeight="bold" as="span">
                    Pacote:
                  </Text>{" "}
                  {MoneyUtils.formatCurrency(billingSettings?.whatsappUtilityMessageFee)}
                  <br />
                  <Text fontWeight="bold" as="span">
                    Excedente:
                  </Text>{" "}
                  {MoneyUtils.formatCurrency(billingSettings?.whatsappUtilityExtraMessageFee)}
                </Td>

                <Td lineHeight={"20px"} verticalAlign="text-top">
                  <Text fontWeight="bold" as="span">
                    Disparos:
                  </Text>{" "}
                  {billingSettings?.whatsappServicePackageLimit}<br />
                  <Text fontWeight="bold" as="span">
                    Pacote:
                  </Text>{" "}
                  {MoneyUtils.formatCurrency(billingSettings?.whatsappServiceMessageFee)}
                  <br />
                  <Text fontWeight="bold" as="span">
                    Excedente:
                  </Text>{" "}
                  {MoneyUtils.formatCurrency(billingSettings?.whatsappServiceExtraMessageFee)}
                </Td>

                <Td lineHeight={"20px"} verticalAlign="text-top">
                  <Text fontWeight="bold" as="span">
                    Disparos:
                  </Text>{" "}
                  {billingSettings?.smsPackageLimit}<br />
                  <Text fontWeight="bold" as="span">
                    Pacote:
                  </Text>{" "}
                  {MoneyUtils.formatCurrency(billingSettings?.smsMessageFee)}
                  <br />
                  <Text fontWeight="bold" as="span">
                    Excedente:
                  </Text>{" "}
                  {MoneyUtils.formatCurrency(billingSettings?.smsMessageFee)}
                </Td>

                <Td lineHeight={"20px"} verticalAlign="text-top">
                  <strong>Total:</strong> {MoneyUtils.formatCurrency(calculateFixedCost(billingSettings))}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      <Divider />
      <Heading size="md" color={colors.primary}>
        Histórico de cobranças
      </Heading>
      <TableInvoices />
    </Flex>
  );
};

export default InvoicesPage;
