import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import * as yup from "yup";
import { apiRoutes } from "../../../../constants/api-routes";
import { colors } from "../../../../constants/colors";
import { IntegrationsService, RegisterVtexOrderHookDto } from "../../../../services/integrations.service";

const schema = yup.object().shape({
  vtexAccountName: yup
    .string()
    .required('O campo "vtexAccountName" é obrigatório.'),
  vtexAppKey: yup
    .string()
    .required('O campo "vtexAppKey" é obrigatório.'),
  vtexAppToken: yup
    .string()
    .required('O campo "vtexAppToken" é obrigatório.'),
  vtexStoreDomain: yup
    .string()
    .required('O campo "vtexStoreDomain" é obrigatório.'),
});

const VtexIntegrationPage = () => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useQuery(apiRoutes.getVtexConfig(), async () => {
    const { data } = await IntegrationsService.getVtexConfig();
    return data;
  }, {
    onSuccess: (data) => {
      setValue("vtexAccountName", data.vtexAccountName || "");
      setValue("vtexStoreDomain", data.vtexStoreDomain || "");
    }
  });

  const registerVtexOrderHook = useMutation(
    (data: RegisterVtexOrderHookDto) => IntegrationsService.registerVtexOrderHook(data),
    {
      onSuccess: () => {
        toast({
          title: "Configurações salvas com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  async function onSubmit(data: any) {
    await registerVtexOrderHook.mutateAsync(data);
  }

  return (
    <Box padding="20px">
      <Heading size="md">Configurar integração</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} mt={4}>
          <FormControl>
            <FormLabel>Nome da conta Vtex (account name)</FormLabel>
            <Input
              placeholder="Ex: minhaloja"
              {...register("vtexAccountName")}
              isInvalid={errors.vtexAccountName?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.vtexAccountName?.message}
            </Text>
          </FormControl>

          <FormControl>
            <FormLabel>Domínio da loja</FormLabel>
            <Input
              placeholder="Ex: www.minhaloja.com.br"
              {...register("vtexStoreDomain")}
              isInvalid={errors.vtexStoreDomain?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.vtexStoreDomain?.message}
            </Text>
          </FormControl>

          <FormControl>
            <FormLabel>Chave do App (app key)</FormLabel>
            <Input
              placeholder="************"
              {...register("vtexAppKey")}
              isInvalid={errors.vtexAppKey?.message}
              sx={{
                '&::placeholder': {
                  color: 'black'
                }
              }}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.vtexAppKey?.message}
            </Text>
          </FormControl>

          <FormControl>
            <FormLabel>Token do App (app token)</FormLabel>
            <Input
              placeholder="************"
              sx={{
                '&::placeholder': {
                  color: 'black'
                }
              }}
              {...register("vtexAppToken")}
              isInvalid={errors.vtexAppToken?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.vtexAppToken?.message}
            </Text>
          </FormControl>

          <Flex justify="flex-end">
            <Button
              type="submit"
              color={colors.white}
              bgColor={colors.primary}
              width="fit-content"
              isLoading={registerVtexOrderHook.isLoading}
            >
              Salvar
            </Button>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};

export default VtexIntegrationPage;
