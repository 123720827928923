export interface JwtPayload {
  email: string;
  name: string;
  exp: number;
  iat: number;
  sub: string;
  companyId: string;
  company: {
    phoneNumberId: string;
    name: string;
  };
}

function base64UrlDecode(base64Url: string) {
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );
}

export const parseJwt = (token: string): JwtPayload | null => {
  try {
    const payload = token.split('.')[1]; // Extract the payload part
    const decodedPayload = base64UrlDecode(payload);
    return JSON.parse(decodedPayload);
  } catch (e) {
    return null;
  }
};
