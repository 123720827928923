import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import * as yup from "yup";
import { colors } from "../../../../constants/colors";
import { IntegrationsService } from "../../../../services/integrations.service";

const schema = yup.object({
  lojaIntegradaApiKey: yup.string().required("'API Key' é obrigatória"),
});

const LojaIntegradaIntegrationsPage = () => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const registerLojaIntegradaOrderHook = useMutation(
    (data: any) => IntegrationsService.registerLojaIntegradaOrderHook(data),
    {
      onSuccess: () => {
        toast({
          title: "Configurações salvas com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  async function onSubmit(data: any) {
    await registerLojaIntegradaOrderHook.mutateAsync(data);
  }

  return (
    <Box padding="20px">
      <Heading size="md">Configurar integração</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} mt={4}>
          <FormControl>
            <FormLabel>API Key</FormLabel>
            <Input
              placeholder="************"
              sx={{
                "&::placeholder": {
                  color: "black",
                },
              }}
              {...register("lojaIntegradaApiKey")}
              isInvalid={errors.lojaIntegradaApiKey?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.lojaIntegradaApiKey?.message}
            </Text>
          </FormControl>

          <Flex justify="flex-end">
            <Button
              type="submit"
              color={colors.white}
              bgColor={colors.primary}
              width="fit-content"
              isLoading={registerLojaIntegradaOrderHook.isLoading}
            >
              Salvar
            </Button>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};

export default LojaIntegradaIntegrationsPage;
