import {
  Box,
  Button,
  Heading,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Th } from "../../../components/CustomTable";
import { apiRoutes } from "../../../constants/api-routes";
import { appPaths } from "../../../constants/app-paths";
import { IntegrationsService } from "../../../services/integrations.service";

enum Integrations {
  ORDERS = "Pedidos",
  ABANDONED_CARTS = "Carrinho abandonado",
  TRACKING_CODE = "Código de rastreio",
  NEW_REGISTER = "Novo cadastro",
}

const integrationOptions: {
  name: string;
  integrations: Integrations[];
  path: string;
  statusKey:
    | "isShopifyActive"
    | "isVtexActive"
    | "isUnboxActive"
    | "isVisualECommerceActive"
    | "isLojaIntegradaActive"
    | "isBlingActive"
    | "isMagazordActive"
    | "isWooCommerceActive"
    | "isMagentoActive"
    | "isIngresseActive";
}[] = [
  {
    name: "Shopify",
    integrations: [Integrations.ORDERS, Integrations.ABANDONED_CARTS],
    path: appPaths.settings.integrationSettings.shopify(),
    statusKey: "isShopifyActive",
  },
  {
    name: "Vtex",
    integrations: [Integrations.ORDERS],
    path: appPaths.settings.integrationSettings.vtex(),
    statusKey: "isVtexActive",
  },
  {
    name: "Bling",
    integrations: [Integrations.ORDERS],
    path: appPaths.settings.integrationSettings.bling(),
    statusKey: "isBlingActive",
  },
  {
    name: "Loja Integrada",
    integrations: [Integrations.ORDERS],
    path: appPaths.settings.integrationSettings.lojaIntegrada(),
    statusKey: "isLojaIntegradaActive",
  },
  // {
  //   name: "Visual E-Commerce",
  //   integrations: [Integrations.ORDERS],
  //   path: appPaths.settings.integrationSettings.index(),
  //   statusKey: "isVisualECommerceActive",
  // },
  // {
  //   name: "Magazord",
  //   integrations: [Integrations.ORDERS],
  //   path: appPaths.settings.integrationSettings.index(),
  //   statusKey: "isMagazordActive",
  // },
  // {
  //   name: "Unbox",
  //   integrations: [Integrations.ABANDONED_CARTS],
  //   path: appPaths.settings.integrationSettings.index(),
  //   statusKey: "isUnboxActive",
  // },
  // {
  //   name: "Woo Commerce",
  //   integrations: [
  //     Integrations.ORDERS,
  //     Integrations.ABANDONED_CARTS,
  //     Integrations.TRACKING_CODE,
  //     Integrations.NEW_REGISTER,
  //   ],
  //   path: appPaths.settings.integrationSettings.index(),
  //   statusKey: "isWooCommerceActive",
  // },
  // {
  //   name: "Magento",
  //   integrations: [Integrations.ORDERS],
  //   path: appPaths.settings.integrationSettings.index(),
  //   statusKey: "isMagentoActive",
  // },
  // {
  //   name: "Ingresse",
  //   integrations: [Integrations.ORDERS],
  //   path: appPaths.settings.integrationSettings.index(),
  //   statusKey: "isIngresseActive",
  // },
];

const IntegrationSettingsPage = () => {
  const navigate = useNavigate();
  const { data: integrationStatus } = useQuery(
    apiRoutes.getIntegrationStatusSummary(),
    async () => {
      const { data } = await IntegrationsService.getIntegrationStatusSummary();
      return data;
    }
  );

  return (
    <Box padding="20px">
      <Heading size="md" mb={5}>
        Integrações
      </Heading>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Plataforma</Th>
              <Th>Integrações</Th>
              <Th>Status</Th>
              <Th>{}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {integrationOptions.map((integration) => (
              <Tr key={integration.name}>
                <Td>{integration.name}</Td>
                <Td>
                  {integration.integrations.map((integration) => (
                    <Tag key={integration} colorScheme={"purple"} mr={2}>
                      {integration}
                    </Tag>
                  ))}
                </Td>
                <Td>
                  {integrationStatus?.[integration.statusKey] ? (
                    <Tag colorScheme="green">Ativo</Tag>
                  ) : (
                    <Tag colorScheme="red">Inativo</Tag>
                  )}
                </Td>
                <Td>
                  <Button size="xs" onClick={() => navigate(integration.path)}>
                    Configurar
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default IntegrationSettingsPage;
