export enum MessageTemplateTypeEnum {
  MARKETING = 'MARKETING',
  REVIEW_REQUEST = 'REVIEW_REQUEST',
  INITIAL_MESSAGE = 'INITIAL_MESSAGE',
  ABANDONED_CART = 'ABANDONED_CART',
  TRACKING_CODE = 'TRACKING_CODE',
  NEW_ORDER = 'NEW_ORDER',
  WELCOME_REGISTRATION = 'WELCOME_REGISTRATION',
  ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
  ORDER_PAYMENT_CONFIRMATION = 'ORDER_PAYMENT_CONFIRMATION',
}

export enum MessageTemplateTypeTextEnum {
  MARKETING = 'Marketing & Engajamento',
  REVIEW_REQUEST = 'Solicitar Review',
  INITIAL_MESSAGE = 'Mensagem Inicial',
  ABANDONED_CART = 'Carrinho Abandonado',
  TRACKING_CODE = 'Rastreamento do Pedido',
  NEW_ORDER = 'Novo Pedido',
  WELCOME_REGISTRATION = 'Mensagem de Bem-vindo Após Cadastro',
  ORDER_CONFIRMATION = 'Confirmação de Pedido',
  ORDER_PAYMENT_CONFIRMATION = 'Confirmação de Pagamento',
}
