import { AxiosResponse } from 'axios';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import { MessageTemplateSuggestion } from '../types/MessageTemplateSuggestion';

const listMessageTemplateSuggestions = async (): Promise<
  AxiosResponse<MessageTemplateSuggestion[]>
> => {
  return request.get(apiRoutes.listMessageTemplateSuggestions());
};

export const MessageTemplateSuggestionsService = {
  listMessageTemplateSuggestions,
};
