import { AxiosResponse } from 'axios';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import { SmsCampaignStatsData } from '../types/SmsCampaignStatsData';

const listSmsCampaigns = async (): Promise<
  AxiosResponse<SmsCampaignStatsData[]>
> => {
  return request.get(apiRoutes.listSmsCampaigns());
};

// const getSmsCampaignDetails = async (
//   campaignId: string
// ): Promise<AxiosResponse<SmsCampaignStatsData>> => {
//   return request.get(apiRoutes.getSmsCampaignDetails(campaignId));
// };

export interface SendOrScheduleSmsCampaignDto {
  templateId: string;
  customerIds: string[];
  templateArgs?: {
    [key: string]: string | undefined;
  };
  filterCriteria?: string;
  scheduledExecutionTime?: string | null;
}

const sendOrScheduleSmsCampaign = async (
  SendOrScheduleSmsCampaignDto: SendOrScheduleSmsCampaignDto,
) => {
  return request.post(
    apiRoutes.sendOrScheduleSmsCampaign(),
    SendOrScheduleSmsCampaignDto,
  );
};

const cancelSmsCampaign = async (campaignId: string) => {
  return request.post(apiRoutes.cancelSmsCampaign(campaignId));
};

export const SmsCampaignsService = {
  listSmsCampaigns,
  // getSmsCampaignDetails,
  sendOrScheduleSmsCampaign,
  cancelSmsCampaign,
};
