import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";
import { Th } from "../../../../../../components/CustomTable";
import { Invoice } from "../../../../../../types/Invoice";
import { MoneyUtils } from "../../../../../../utils/money.utils";

interface ModalInvoiceDetailsProps {
  invoice: Invoice | null;
  onClose: () => void;
}

const ModalInvoiceDetails = ({
  invoice,
  onClose,
}: ModalInvoiceDetailsProps) => {
  return (
    <Modal isOpen={!!invoice} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth={"60vw"} alignItems={"center"}>
        <ModalHeader>Detalhes da fatura</ModalHeader>
        <ModalCloseButton />
        {invoice && (
          <ModalBody>
            <Flex mb={5} justifyContent="space-between">
              <Box>
                <Box fontWeight="bold" mb={2}>
                  Mês de referência:
                </Box>
                <Box>{format(new Date(invoice.referenceMonth), 'MM/yyyy')}</Box>
              </Box>
              <Box>
                <Box fontWeight="bold" mb={2}>
                  Total da fatura:
                </Box>
                <Box>{MoneyUtils.formatCurrency(invoice.value)}</Box>
              </Box>
              <Box>
                <Box fontWeight="bold" mb={2}>
                  Data de Vencimento:
                </Box>
                <Box>{format(new Date(invoice.dueDate), "dd/MM/yyyy")}</Box>
              </Box>
            </Flex>
            <Heading size="sm" mb={5} mt={5}>
              Itens da fatura
            </Heading>
            <TableContainer>
              <Table variant="striped" size="sm" width="auto">
                <Thead>
                  <Tr>
                    <Th>Produto</Th>
                    <Th>Quantidade</Th>
                    <Th>Valor Un.</Th>
                    <Th>Desconto</Th>
                    <Th>Total</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {invoice?.invoiceItems.map((item) => (
                    <Tr key={item.id}>
                      <Td>{item.name}</Td>
                      <Td>{item.quantity}</Td>
                      <Td>{MoneyUtils.formatCurrency(item.unitPrice)}</Td>
                      <Td>{MoneyUtils.formatCurrency(item.discount)}</Td>
                      <Td>{MoneyUtils.formatCurrency(item.totalPrice)}</Td>
                    </Tr>
                  ))}
                  <Tr fontWeight={"bold"}>
                    <Td colSpan={4} textAlign="right">
                      Total:
                    </Td>
                    <Td>
                      {MoneyUtils.formatCurrency(
                        invoice?.invoiceItems?.reduce(
                          (acc, curr) => acc + curr.totalPrice,
                          0
                        ) || 0
                      )}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ModalInvoiceDetails;
