import { AxiosResponse } from 'axios';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';

const getBlingAuthUrl = (): Promise<
  AxiosResponse<{
    url: string;
  }>
> => {
  return request.get(apiRoutes.getBlingAuthUrl());
};
export const BlingService = {
  getBlingAuthUrl,
};
