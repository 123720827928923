function roundToNearestPowerOfTen(number: number) {
  const power = Math.floor(Math.log10(number));
  const multiplier = 10 ** power;
  return Math.ceil(number / multiplier) * multiplier;
}

function getPercentValue(value?: number, total?: number) {
  return `${(((value || 0) / (total || 1)) * 100).toFixed(2)}%`;
}

export const NumberUtils = {
  roundToNearestPowerOfTen,
  getPercentValue,
};
