import { Card, CardBody, CardHeader, Center } from '@chakra-ui/react';
import CustomECharts from '../../../../../components/CustomECharts';
import { colors } from '../../../../../constants/colors';

interface CardTicketsPerCategoryChartProps {
  categories: string[];
  values: number[];
}

const colorEntries = Object.entries(colors.chartColors);

const getColorForCategory = (category: string) => {
  if (category.length === 0) {
    if (!Array.isArray(colorEntries) || colorEntries.length === 0) {
      throw new Error('colorEntries deve ser um array não vazio.');
    }

    const hash = category
      .split('')
      .reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const index = hash % colorEntries.length;
    return colorEntries[index][1].secondary;
  }
};

export default function CardTicketsPerCategoryChart({
  categories,
  values,
}: CardTicketsPerCategoryChartProps) {
  return (
    <Card variant={"outline"}>
      <CardHeader>
        <Center>
          <b>Atendimentos Por Categoria</b>
        </Center>
      </CardHeader>
      <CardBody>
        <CustomECharts
          chartWidth={"100%"}
          chartHeight="450px"
          marginTop={"0px"}
          option={{
            xAxis: {
              type: "category",
              data: categories,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                data: values.map((value, index) => ({
                  value,
                  itemStyle: {
                    color: getColorForCategory(categories[index]),
                  },
                })),
                type: 'bar',
              },
            ],
          }}
        />
      </CardBody>
    </Card>
  );
}
