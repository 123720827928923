import { ExperimentStatus } from './CampaignExperiment';
import { WhatsappCampaignStatus } from './Prisma';

export enum CampaignType {
  STANDARD = 'standard',
  EXPERIMENT_TEST_GROUP = 'experiment_test_group',
  EXPERIMENT_WINNING_GROUP = 'experiment_winning_group',
}

export interface WhatsappCampaignStatsData {
  id: string;
  created_at: Date;
  total_recipients: number;
  campaign_experiment_id?: string;
  type: CampaignType;
  experiment_status?: ExperimentStatus;
  scheduled_execution_time?: Date;
  status: WhatsappCampaignStatus;
  template_name: string;
  template_text: string;
  template_id: string;
  template_media_url: string;
  filter_criteria: string;
  failed: number;
  enqueued: number;
  sent: number;
  delivered: number;
  read: number;
  clicks: number;
  replies: number;
  engaged: number;
  total_rows: number;
}
