import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import { FormDataUtils } from '../utils/form-data.utils';

export interface UploadOrdersDto extends Record<any, any> {}

const uploadOrders = async (uploadOrdersDto: UploadOrdersDto) => {
  const { file } = uploadOrdersDto;
  const data = FormDataUtils.convertJsonToFormData({
    file,
  });

  return request.post(apiRoutes.uploadOrders(), data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 60000,
  });
};

export const OrdersService = {
  uploadOrders,
};
