import { queryStringDelimiter } from '../constants/query-string-delimiter';
import { MoneyUtils } from './money.utils';
import { UrlUtils } from './url.utils';
import { format, parse } from 'date-fns';

function getFormattedValue(value: any, type: string) {
  const formatters: Record<string, (value: any) => any> = {
    money: (value) => MoneyUtils.formatCurrency(value * 100),
    number: (value) => Number(value),
    date: (value) =>
      format(parse(value, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy'),
    bool: (value) => (value ? 'Sim' : 'Não'),
    id: () => 1,
    ids: (value) => value.split(queryStringDelimiter).length,
    sortBy: (value) => {
      const sortByTranslation: Record<string, string> = {
        createdAtDesc: 'Criado em (desc)',
        nameAsc: 'Nome (asc)',
        totalPurchasesDesc: 'Total em Compras (desc)',
        totalOrdersDesc: 'Total de Pedidos (desc)',
        lastPurchaseAtDesc: 'Última Compra (desc)',
        random: 'Aleatório',
      };
      return sortByTranslation[value] || value;
    },
    text: (value) => {
      return value.split(queryStringDelimiter).join(', ').replaceAll('+', ' ');
    },
  };

  return formatters[type] ? formatters[type](value) : value;
}

function parseFilterCriteria(filterCriteria: string) {
  const translatedKeys: Record<
    string,
    {
      title: string;
      type:
        | 'string'
        | 'number'
        | 'id'
        | 'date'
        | 'bool'
        | 'money'
        | 'ids'
        | 'sortBy'
        | 'text';
      section:
        | 'general'
        | 'clientes'
        | 'tags'
        | 'campanhas'
        | 'compras'
        | 'produtos';
    }
  > = {
    searchQuery: {
      title: 'Campo de Pesquisa',
      type: 'string',
      section: 'general',
    },
    sortBy: { title: 'Ordenado Por', type: 'sortBy', section: 'general' },
    selectedTags: {
      title: 'Tags Selecionadas',
      type: 'ids',
      section: 'tags',
    },
    excludedTags: { title: 'Tags Excluídas', type: 'ids', section: 'tags' },
    selectedEngagementActionTypes: {
      title: 'Tipos de Ações de Engajamento Selecionados',
      type: 'string',
      section: 'campanhas',
    },
    selectedEngagementTemplateIds: {
      title: 'Templates selecionados',
      type: 'ids',
      section: 'campanhas',
    },
    isRemarketing: {
      title: 'É Remarketing',
      type: 'bool',
      section: 'campanhas',
    },
    excludedTemplateIds: {
      title: 'Templates excluídos',
      type: 'id',
      section: 'campanhas',
    },
    minDaysSinceLastCampaign: {
      title: 'Mínimo de Dias desde a Última Campanha',
      type: 'number',
      section: 'campanhas',
    },
    startOrdersCreatedAt: {
      title: 'Pedidos Criados a partir de',
      type: 'date',
      section: 'compras',
    },
    endOrdersCreatedAt: {
      title: 'Pedidos Criados até',
      type: 'date',
      section: 'compras',
    },
    minTotalPurchases: {
      title: 'Mínimo em Compras',
      type: 'money',
      section: 'compras',
    },
    maxTotalPurchases: {
      title: 'Máximo em Compras',
      type: 'money',
      section: 'compras',
    },
    minTotalOrders: {
      title: 'Mínimo de Pedidos',
      type: 'number',
      section: 'compras',
    },
    maxTotalOrders: {
      title: 'Máximo de Pedidos',
      type: 'number',
      section: 'compras',
    },
    minAverageOrderValue: {
      title: 'Valor Médio Mínimo do Pedido',
      type: 'money',
      section: 'compras',
    },
    maxAverageOrderValue: {
      title: 'Valor Médio Máximo do Pedido',
      type: 'money',
      section: 'compras',
    },
    minAverageItemValue: {
      title: 'Valor Médio Mínimo do Item',
      type: 'money',
      section: 'compras',
    },
    maxAverageItemValue: {
      title: 'Valor Médio Máximo do Item',
      type: 'money',
      section: 'compras',
    },
    minDaysSinceLastPurchase: {
      title: 'Mínimo de Dias desde a Última Compra',
      type: 'number',
      section: 'compras',
    },
    maxDaysSinceLastPurchase: {
      title: 'Máximo de Dias desde a Última Compra',
      type: 'number',
      section: 'compras',
    },
    selectedProductIds: {
      title: 'Produtos Selecionados',
      type: 'ids',
      section: 'produtos',
    },
    excludedProductIds: {
      title: 'Produtos Excluídos',
      type: 'ids',
      section: 'produtos',
    },
    minProductQuantity: {
      title: 'Mínimo de pedidos por produto',
      type: 'number',
      section: 'produtos',
    },
    maxProductQuantity: {
      title: 'Máximo de pedidos por produto',
      type: 'number',
      section: 'produtos',
    },
    isLastProductPurchased: {
      title: 'É o último produto comprado',
      type: 'bool',
      section: 'produtos',
    },
    minDaysSinceLastProductPurchase: {
      title: 'Mínimo de dias desde a última compra do produto',
      type: 'number',
      section: 'produtos',
    },
    maxDaysSinceLastProductPurchase: {
      title: 'Máximo de dias desde a última compra do produto',
      type: 'number',
      section: 'produtos',
    },
    productNameContains: {
      title: 'Nome do produto contém',
      type: 'string',
      section: 'produtos',
    },
    isScheduledCampaignsVisible: {
      title: 'Campanha Agendada',
      type: 'bool',
      section: 'campanhas',
    },
    selectedStates: {
      title: 'Estados Selecionados',
      type: 'text',
      section: 'clientes',
    },
  };

  const filterCriteriaObj = UrlUtils.convertQueryStringToObject(filterCriteria);

  return Object.keys(translatedKeys)
    .filter((key) => key in filterCriteriaObj)
    .map((key) => ({
      title: translatedKeys[key].title,
      value: getFormattedValue(
        filterCriteriaObj[key],
        translatedKeys[key].type,
      ),
      section: translatedKeys[key].section,
    }));
}

export const CustomerFilterUtils = {
  parseFilterCriteria: (filterCriteria: string) =>
    parseFilterCriteria(filterCriteria),
};
