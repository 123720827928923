import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useState } from "react";
import { useQuery } from "react-query";
import { Th } from "../../../../../../components/CustomTable";
import { apiRoutes } from "../../../../../../constants/api-routes";
import { InvoicesService } from "../../../../../../services/invoices.service";
import { Invoice } from "../../../../../../types/Invoice";
import { MoneyUtils } from "../../../../../../utils/money.utils";
import ModalInvoiceDetails from "./ModalInvoiceDetails";

const TableInvoices = () => {
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);

  const { data: invoices } = useQuery(apiRoutes.listInvoices(), async () => {
    const { data } = await InvoicesService.listInvoices();
    return data;
  });

  function handleClickInvoiceDetails(invoice: Invoice) {
    setSelectedInvoice(invoice);
  }

  return (
    <TableContainer>
      <ModalInvoiceDetails
        onClose={() => setSelectedInvoice(null)}
        invoice={selectedInvoice}
      />
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Vencimento</Th>
            <Th>Mês de referencia</Th>
            <Th>Total</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody>
          {invoices?.map((invoice) => (
            <Tr key={invoice.id}>
              <Td>{format(new Date(invoice.dueDate), "dd/MM/yyyy")}</Td>
              <Td>{format(new Date(invoice.referenceMonth), "MM/yyyy")}</Td>
              <Td>{MoneyUtils.formatCurrency(invoice.value)}</Td>
              <Td>
                <Button onClick={() => handleClickInvoiceDetails(invoice)}>
                  Detalhes
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableInvoices;
