import { PaginatedResponse } from './../types/PaginatedResponse.d';
import { AxiosResponse } from 'axios';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import { WhatsappCampaignStatsData } from '../types/WhatsappCampaignsData';

const listWhatsappCampaigns = async (
  page: number,
  perPage: number,
): Promise<AxiosResponse<PaginatedResponse<WhatsappCampaignStatsData>>> => {
  return request.get(apiRoutes.listWhatsappCampaigns(page, perPage));
};

const getWhatsappCampaignDetails = async (
  campaignId: string,
): Promise<AxiosResponse<WhatsappCampaignStatsData>> => {
  return request.get(apiRoutes.getWhatsappCampaignDetails(campaignId));
};

export interface SendOrScheduleWhatsappCampaignDto {
  templateId: string;
  customerIds: string[];
  templateArgs?: {
    [key: string]: string | undefined;
  };
  filterCriteria?: string;
  scheduledExecutionTime?: string | null;
}

const sendOrScheduleWhatsappCampaign = async (
  SendOrScheduleWhatsappCampaignDto: SendOrScheduleWhatsappCampaignDto,
) => {
  return request.post(
    apiRoutes.sendOrScheduleWhatsappCampaign(),
    SendOrScheduleWhatsappCampaignDto,
  );
};

const cancelWhatsappCampaign = async (campaignId: string) => {
  return request.post(apiRoutes.cancelWhatsappCampaign(campaignId));
};

export const WhatsappCampaignsService = {
  listWhatsappCampaigns,
  getWhatsappCampaignDetails,
  sendOrScheduleWhatsappCampaign,
  cancelWhatsappCampaign,
};
